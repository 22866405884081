// extracted by mini-css-extract-plugin
export var button = "styles-module--button--5f78e";
export var buttonsContainer = "styles-module--buttonsContainer--a804e";
export var company = "styles-module--company--4bc5d";
export var container = "styles-module--container--42507";
export var content = "styles-module--content--3a349";
export var customerStories = "styles-module--customerStories--b646f";
export var drivaModal = "styles-module--drivaModal--76ac1";
export var gridImage = "styles-module--gridImage--0ee45";
export var gridImageContainer = "styles-module--gridImageContainer--0f235";
export var image = "styles-module--image--bb806";
export var imageGrid = "styles-module--imageGrid--9535c";
export var imageGridSection = "styles-module--imageGridSection--bfef1";
export var imageTextContainer = "styles-module--imageTextContainer--56455";
export var itemTitle = "styles-module--itemTitle--31ee0";
export var leftColumn = "styles-module--leftColumn--f9c5b";
export var mintBg = "styles-module--mintBg--4ecd0";
export var modalContent = "styles-module--modalContent--c3a39";
export var modalImage = "styles-module--modalImage--2cf7f";
export var modalImageContainer = "styles-module--modalImageContainer--f010f";
export var name = "styles-module--name--bd89a";
export var role = "styles-module--role--b0aaa";
export var title = "styles-module--title--c5dbf";
export var visibleArrows = "styles-module--visibleArrows--c173a";
export var yellowArrows = "styles-module--yellowArrows--d80e6";