import React from 'react';
import { SocialLinks, PostCard, SideBarCta } from 'components';
import * as styles from './styles.module.scss';

const BlogPostSidebarShortForm = ({ posts, className, cta, location }) => (
  <div className={`${styles.sidebarSection} ${className || ''}`}>
    <div className={styles.container}>
      <SocialLinks title="Share" location={location} />
      <div className={styles.heading}>Related articles</div>
      {posts.map((post, index) => (
        <PostCard post={post} key={`post_${index}`} sideBar />
      ))}
    </div>
    <SideBarCta ctaData={cta} />
  </div>
);

export default BlogPostSidebarShortForm;
