import React from 'react';
import { Section, Image, Link, OnVisible } from 'components';
import { ReactComponent as PdfIcon } from '../../images/pdf.svg';

import * as styles from './styles.module.scss';

const Documents = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, image, documents_tagline: tagline } = primary;

  // content checks
  const titleHtml = title?.html;
  const hasImage = image?.url;

  return (
    <Section
      sliceName="Documents"
      className={`image-contact-steps ${styles.imageContactSteps}`}
      containerClassName={styles.container}
      id="documents-slice"
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      <div className={styles.grid}>
        <div className={styles.leftCol}>
          {hasImage && <Image className={styles.image} image={image} objectFit="cover" />}
        </div>
        <div className={styles.documentContainer}>
          <div className={styles.taglineContainer}>
            <PdfIcon className={styles.icon} />
            {tagline?.html && <div dangerouslySetInnerHTML={{ __html: tagline.html }} className={styles.tagline} />}
          </div>
          <div className={styles.documentsList}>
            {items.map((item, index) => {
              const { document_title: docTitle, document: doc, button_text: buttonText } = item;
              return (
                <div className={styles.document} style={{ transitionDelay: `${0.2 * index}s` }} key={docTitle.text}>
                  {docTitle?.text && (
                    <div className={styles.docTitle} dangerouslySetInnerHTML={{ __html: docTitle?.html }} />
                  )}
                  <Link to={doc.url} className={`button ${styles.button}`}>
                    {buttonText?.text || 'Download'}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Documents;
