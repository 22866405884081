// extracted by mini-css-extract-plugin
export var cell = "styles-module--cell--a7853";
export var cellLarge = "styles-module--cellLarge--c4c0e";
export var cellLarger = "styles-module--cellLarger--ebe72";
export var cellText = "styles-module--cellText--5ab3e";
export var comparisonTable = "styles-module--comparisonTable--f3181";
export var container = "styles-module--container--06d2e";
export var content = "styles-module--content--0b622";
export var headerCell = "styles-module--headerCell--37247";
export var highlightedRow = "styles-module--highlightedRow--d837f";
export var icon = "styles-module--icon--ba0db";
export var iconWrapper = "styles-module--iconWrapper--82466";
export var responsiveVariant = "styles-module--responsiveVariant--cb4ff";
export var row = "styles-module--row--683cb";
export var table = "styles-module--table--b9134";
export var tableView = "styles-module--tableView--53e98";
export var tableWrapper = "styles-module--tableWrapper--7777c";
export var title = "styles-module--title--c85ff";