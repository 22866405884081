import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Link, Image } from 'components';
import * as styles from './styles.module.scss';

const InTextCta = (props) => {
  const { data } = props;
  const { primary } = data;
  const { cta_link: ctaLink, cta_text: ctaText, cta_image: image, ctatitle: ctaTitle, theme } = primary;

  const query = graphql`
    query {
      ...InTextCtaSettings
    }
  `;

  const staticData = useStaticQuery(query);

  const settingsData = staticData?.prismicSettings?.data || {};

  const {
    in_text_cta_link: settingsCtaLink,
    in_text_cta_image: settingsCtaImage,
    in_text_cta_text: settingsCtaText,
    in_text_ctatitle: settingsCtaTitle,
    in_text_theme: settingsTheme,
  } = settingsData;

  const dataObj = {
    ctaImage: image?.url ? image : settingsCtaImage,
    ctaLink: ctaLink?.url || settingsCtaLink?.url,
    ctaText: ctaText?.text || settingsCtaText?.text,
    ctaTitle: ctaTitle?.text || settingsCtaTitle?.text,
    ctaTheme: theme || settingsTheme,
  };

  if (!dataObj?.ctaLink || dataObj?.ctaTitle?.length === 0 || !dataObj.ctaImage) {
    return null;
  }

  const lineOne = dataObj?.ctaTitle?.slice(0, 10);
  const lineTwo = dataObj?.ctaTitle?.slice(10);

  return (
    <Section
      sliceName="IntextCta"
      className={`intextCta ${styles.ctaContainer} ${theme === 'light' ? styles.lightTheme : styles.darkTheme} `}
    >
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <Image className={styles.image} image={dataObj?.ctaImage} objectFit="CONTAIN" />
        </div>
        <div className={styles.textContainer}>
          {/* TITLE */}
          <div className={styles.title}>
            {lineOne && <span className={styles.titleLine}>{lineOne}</span>}
            {lineTwo && <span className={styles.titleLine}>{lineTwo}</span>}
          </div>
          {/* CTA LINK */}
          <Link
            className={`button primary ${styles.ctaButton || ''} ${
              theme === 'light' ? styles.lightCta : styles.darkCta
            }`}
            to={dataObj?.ctaLink}
          >
            {dataObj?.ctaText || 'Use our free calculator'}
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default InTextCta;
