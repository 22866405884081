// extracted by mini-css-extract-plugin
export var container = "styles-module--container--a394b";
export var content = "styles-module--content--f5b87";
export var greenCircle = "styles-module--greenCircle--a9c4f";
export var iconContainer = "styles-module--iconContainer--8e98e";
export var image = "styles-module--image--e1791";
export var imageContactSteps = "styles-module--imageContactSteps--b4f09";
export var leftCol = "styles-module--leftCol--bf8a3";
export var rightCol = "styles-module--rightCol--4fe6c";
export var stepContainer = "styles-module--stepContainer--d0ccd";
export var stepText = "styles-module--stepText--60e92";
export var stepTitle = "styles-module--stepTitle--e78e0";
export var title = "styles-module--title--39bc9";
export var visibleStep = "styles-module--visibleStep--9d2dd";