import { Form, init } from '@feathery/react';
import { Section } from 'components';
import React from 'react';
import * as styles from './styles.module.scss';

const Feathery = (props) => {
  const { data } = props;
  const { primary } = data;
  const { feathery_form_id: formId } = primary;
  const FEATHERY_KEY = process.env.GATSBY_FEATHERY_SDK_KEY;
  init(FEATHERY_KEY);

  if (!formId?.text) return null;

  return (
    <Section sliceName="feathery" className={styles.feathery} containerClassName={styles.container}>
      <Form formId={formId?.text} className={styles.form} />
    </Section>
  );
};

export default Feathery;
