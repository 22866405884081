import React from 'react';
import { Skeleton } from 'components';
import * as styles from './styles.module.scss';

const RateItem = ({
  returnData,
  animate,
  repaymentFrequencyOptions,
  isBadResponse,
  decimalHelper,
  type,
  className,
}) => {
  if (type === 'interest') {
    return (
      <div className={`${styles.rateItem} ${className || ''}`}>
        {!returnData?.error && (
          <h4
            className={
              animate && returnData?.interestRate ? styles.animateMainTextPercentage : styles.mainTextPercentage
            }
            style={{ opacity: returnData?.error && 0.3 }}
          >
            {returnData?.interestRate && !isBadResponse && decimalHelper(returnData?.interestRate)}
            {!returnData?.interestRate && !isBadResponse && !animate && `4.5%`}
          </h4>
        )}
        {returnData?.error && (
          <h4
            className={animate ? styles.mainTextPercentageInvalidAnimate : styles.mainTextPercentageInvalid}
            style={{ opacity: returnData?.error && 0.3 }}
          >
            ...
          </h4>
        )}
        <div className={styles.skeletonContainer}>{animate && <Skeleton isSmaller />}</div>
        <p className={styles.tagline}>Interest rate</p>
      </div>
    );
  }

  return (
    <div className={`${styles.rateItem} ddd ${styles.comparisonItem} ${className || ''}`}>
      <div className={styles.rateItem}>
        {!returnData?.error && (
          <h4
            className={animate ? styles.animateMainTextPercentage : styles.mainTextPercentage}
            style={{ opacity: isBadResponse && 0.3 }}
          >
            {returnData?.comparisonRate && !isBadResponse && decimalHelper(returnData?.comparisonRate)}
            {!returnData?.comparisonRate && !isBadResponse && !animate && `4.05%`}
          </h4>
        )}
        {returnData?.error && (
          <h4
            className={animate ? styles.mainTextPercentageInvalidAnimate : styles.mainTextPercentageInvalid}
            style={{ opacity: isBadResponse && 0.3 }}
          >
            ...
          </h4>
        )}

        <div className={styles.skeletonContainer}>{animate && <Skeleton isSmaller />}</div>
      </div>
      <p className={styles.tagline}>Comparison rate</p>
    </div>
  );
};

export default RateItem;
