import React, { useEffect, useState } from 'react';
import { Footer, Header, SEO, NoJs, WmAscii, ReferralBanner } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import useSessionStorage from '../../../hooks/useSessionStorage';
import '../../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, uid, seo, location, navLink } = props;

  const referrerQuery = graphql`
    query {
      ...referrerPartners
    }
  `;

  const {
    prismicSettings: {
      data: { referral_partners: referralPartners },
    },
  } = useStaticQuery(referrerQuery);

  const [activeReferrer, setActiveReferrer] = useState(null);

  const getQueryStringsAsObject = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryStrings = {};

    for (const [key, value] of params.entries()) {
      queryStrings[key] = value;
    }

    return queryStrings;
  };

  useEffect(() => {
    // retrive queries if they exist
    const utms = sessionStorage.getItem('queriesObj');
    const trackUtms = sessionStorage.getItem('trackUtms');
    const parsedUtms = utms ? JSON.parse(utms) : null;
    const fromReferrer = parsedUtms && parsedUtms?.utm_campaign;

    if (fromReferrer) {
      const referrer = referralPartners.find((partner) => partner?.id?.text === fromReferrer);

      if (referrer) {
        setActiveReferrer(referrer);
      }
    }

    if (!parsedUtms?.utm_campaign && navLink?.url && trackUtms !== 'false') {
      const newUtms = getQueryStringsAsObject(navLink.url);
      sessionStorage.setItem('queriesObj', JSON.stringify(newUtms));
    }
    sessionStorage.setItem('trackUtms', 'false');
  }, []);

  return (
    <>
      <NoJs />
      {/* <WmAscii /> */}
      <Header location={location} />
      <main className={uid ? `template-${uid}` : ''}>
        {!customSEO && <SEO {...seo} location={location} />}
        {children}
      </main>
      <Footer />
      {activeReferrer && <ReferralBanner className="referrer-banner-comp" referrer={activeReferrer} />}
    </>
  );
};

export default Layout;
