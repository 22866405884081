/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */

import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as styles from './styles.module.scss';

const ScrollSequencer = ({
  width,
  height,
  className,
  canvasContainerClassName,
  canvasClassName,
  visible,
  filePrefix,
  fileSuffix,
  fileExtension,
  padAmount,
  totalFrames,
}) => {
  // ref
  const containerRef = useRef();
  const canvasRef = useRef();

  // state
  const [images, setImages] = useState([]);

  // ===========================================================================
  // variables

  const frames = Array(totalFrames)
    .fill(null)
    .map((_, index) => `${filePrefix}${`${index + 1}`.padStart(padAmount, '0')}${fileSuffix || ''}${fileExtension}`);

  // methods
  const preload = async () => {
    const imagesArr = frames.map((frame) => {
      const img = new Image();
      img.src = frame;
      return img;
    });
    setImages(imagesArr);
  };

  const draw = (activeFrame) => {
    if (!canvasRef?.current) return;

    const context = canvasRef.current.getContext(`2d`);
    context.imageSmoothingEnabled = false;
    if (fileExtension === '.png') {
      context.fillStyle = '#F2F4F0';
      context.fillRect(0, 0, width * window.devicePixelRatio, height * window.devicePixelRatio);
    }
    context.drawImage(images[activeFrame], 0, 0, width * window.devicePixelRatio, height * window.devicePixelRatio);

    const requestId = requestAnimationFrame(() => draw(activeFrame));
    cancelAnimationFrame(requestId);
  };

  const percentToFrame = (percent, min, max) => parseInt(min + (percent / 100) * (max - min), 10);

  // ===========================================================================
  // Sync node dimensions with canvas dimensions and draw initial image
  useEffect(() => {
    if (!canvasRef?.current) return null;

    const context = canvasRef.current.getContext(`2d`);
    const img = new Image();
    const initialImage = frames[totalFrames - 1];

    canvasRef.current.width = width * window.devicePixelRatio;
    canvasRef.current.height = height * window.devicePixelRatio;

    img.src = initialImage;
    img.onload = () => {
      context.imageSmoothingEnabled = false;
      context.drawImage(img, 0, 0, width * window.devicePixelRatio, height * window.devicePixelRatio);
    };
    preload();
  }, [canvasRef]);

  // ===========================================================================
  // scroll handler
  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef?.current || images.length < 1) return null;

      const { innerHeight } = window;

      const boundingRect = containerRef?.current?.getBoundingClientRect();
      const containerYPosition = boundingRect?.top;
      const containerHeight = boundingRect?.height;

      const traversalArea = containerHeight * 0.66;
      const normalizedScrollPosition = -(containerYPosition - innerHeight * 0.3);

      let progress = (normalizedScrollPosition / traversalArea) * 100;

      if (progress > 100) {
        progress = 100;
      } else if (progress < 0) {
        progress = 0;
      }

      const framesMin = 0;

      const activeFrame = percentToFrame(progress, framesMin, totalFrames - 1);
      draw(activeFrame);
    };

    window.addEventListener(`scroll`, handleScroll, { passive: true });

    return () => window.removeEventListener(`scroll`, handleScroll);
  }, [containerRef, images]);

  // ===========================================================================
  // render

  return (
    <div className={`${styles.section} ${className || ''} scroll-sequencer`} ref={containerRef}>
      <Helmet>
        {frames.map((frame) => (
          <link rel="preload" href={frame} as="image" />
        ))}
      </Helmet>
      <div className={`${styles.canvasContainer} ${canvasContainerClassName || ''}`}>
        <canvas ref={canvasRef} className={`${styles.canvas} ${canvasClassName || ''}`} />
      </div>
    </div>
  );
};

export default ScrollSequencer;
