import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'components';
import * as styles from './styles.module.scss';
import './footerNavGrid.scss';

const FooterNavigation = () => {
  const {
    prismicSettings: { data: footerNavigationDataRaw },
  } = useStaticQuery(settingsQuery);

  const footerNavData = [];

  [1, 2, 3, 4, 5, 6].forEach((count) => {
    footerNavData.push({
      footerNavLink: footerNavigationDataRaw[`footer_navigation_link_${count}`],
      footerNavLinkUrl: footerNavigationDataRaw[`footer_navigation_link_${count}_url`],
      footerNavLinkSublinks: footerNavigationDataRaw[`footer_navigation_link_${count}_sublinks`],
      // Generate className
      footerNavClassName: `sectionClass${count}`,
    });
  });

  return (
    <nav className={styles.footerLinksContainer}>
      {footerNavData.map((navColData) => {
        const title = navColData?.footerNavLink?.text;
        const link = navColData?.footerNavLinUrl?.url;
        const sublinks = navColData?.footerNavLinkSublinks;
        // Assign className
        const sectionClass = navColData?.footerNavClassName;

        return (
          <Fragment key={title}>
            <FooterNavigationCol title={title} link={link} sublinks={sublinks} sectionClass={sectionClass} />
          </Fragment>
        );
      })}
    </nav>
  );
};

const FooterNavigationCol = (props) => {
  const { title, link, sublinks, sectionClass } = props;
  if (!title) return null;
  return (
    <div key={title} className={`footer-grid-item ${sectionClass}`}>
      {link ? (
        <Link to={link} className={styles.linkTitle}>
          {title}
        </Link>
      ) : (
        <p className={styles.linkTitle}>{title}</p>
      )}
      <div className={styles.sublinksContainer}>
        {sublinks.map((sublink) => {
          const sublinkTitleText = sublink?.text?.text;
          const sublinkTitleUrl = sublink?.link?.url;

          return (
            <Link to={sublinkTitleUrl} key={sublinkTitleText} className={styles.sublink}>
              {sublinkTitleText}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNavigation;

export const settingsQuery = graphql`
  query FooterNavigation {
    prismicSettings {
      data {
        footer_navigation_link_1 {
          html
          text
        }
        footer_navigation_link_1_url {
          uid
          url
        }
        footer_navigation_link_1_sublinks {
          text {
            text
          }
          link {
            document {
              ... on PrismicPage {
                uid
              }
            }
            url
          }
        }
        footer_navigation_link_2 {
          html
          text
        }
        footer_navigation_link_2_url {
          uid
          url
        }
        footer_navigation_link_2_sublinks {
          text {
            text
          }
          link {
            document {
              ... on PrismicPage {
                uid
              }
            }
            url
          }
        }
        footer_navigation_link_3 {
          html
          text
        }
        footer_navigation_link_3_url {
          uid
          url
        }
        footer_navigation_link_3_sublinks {
          text {
            html
            text
          }
          link {
            document {
              ... on PrismicPage {
                uid
              }
            }
            url
          }
        }
        footer_navigation_link_4 {
          html
          text
        }
        footer_navigation_link_4_url {
          uid
          url
        }
        footer_navigation_link_4_sublinks {
          text {
            text
          }
          link {
            document {
              ... on PrismicPage {
                uid
              }
            }
            url
          }
        }
        footer_navigation_link_5 {
          html
          text
        }
        footer_navigation_link_5_url {
          uid
          url
        }
        footer_navigation_link_5_sublinks {
          text {
            text
          }
          link {
            document {
              ... on PrismicPage {
                uid
              }
            }
            url
          }
        }
        footer_navigation_link_6 {
          html
          text
        }
        footer_navigation_link_6_url {
          uid
          url
        }
        footer_navigation_link_6_sublinks {
          text {
            text
          }
          link {
            document {
              ... on PrismicPage {
                uid
              }
            }
            url
          }
        }
      }
    }
  }
`;
