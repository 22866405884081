// extracted by mini-css-extract-plugin
export var activeCard = "styles-module--activeCard--8d6ea";
export var arrowContainer = "styles-module--arrowContainer--4107c";
export var card = "styles-module--card--26ae1";
export var cardDesc = "styles-module--cardDesc--e5f2d";
export var cardFeatures = "styles-module--cardFeatures--97a42";
export var cardTitle = "styles-module--cardTitle--07b3a";
export var cardTop = "styles-module--cardTop--8c641";
export var cardsContainer = "styles-module--cardsContainer--750d5";
export var chevron = "styles-module--chevron--b808e";
export var description = "styles-module--description--64bc2";
export var featuresOpenMobile = "styles-module--featuresOpenMobile--1fb7e";
export var greenArrows = "styles-module--greenArrows--34da3";
export var icon = "styles-module--icon--a47da";
export var mobileToggle = "styles-module--mobileToggle--c44f5";
export var property = "styles-module--property--8fea8";
export var section = "styles-module--section--77a98";
export var title = "styles-module--title--9ef3a";
export var value = "styles-module--value--662be";
export var visArrows = "styles-module--visArrows--5654a";
export var visibleCard = "styles-module--visibleCard--39c56";