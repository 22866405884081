import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FooterDisclaimer, FooterLegal, FooterNavigation, Link, CountrySwitcher } from 'components';
import { ReactComponent as DrivaFooterLogo } from '../../../images/driva-footer-logo.svg';
import { ReactComponent as Mail } from '../../../images/mail.svg';
import { ReactComponent as Phone } from '../../../images/phone.svg';
import { ReactComponent as Facebook } from '../../../images/socials/facebook.svg';
import { ReactComponent as Instagram } from '../../../images/socials/instagram.svg';
import { ReactComponent as Linkedin } from '../../../images/socials/linkedin.svg';
import { ReactComponent as TikTok } from '../../../images/socials/tiktok.svg';
import * as styles from './styles.module.scss';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query settings {
      prismicSettings {
        data {
          facebook {
            url
          }
          tiktok {
            url
          }
          linkedin {
            url
          }
          instagram {
            url
          }
          abn {
            text
          }
          australian_credit_license_no {
            text
          }
        }
      }
    }
  `);

  const {
    prismicSettings: { data: settingsData },
  } = data;

  const { facebook, tiktok, linkedin, instagram, abn, australian_credit_license_no: licenseNo } = settingsData;

  const socials = [
    { link: linkedin?.url, comp: <Linkedin className={styles.icon} /> },
    { link: facebook?.url, comp: <Facebook className={styles.icon} /> },
    { link: instagram?.url, comp: <Instagram className={styles.icon} /> },
    { link: tiktok?.url, comp: <TikTok className={styles.icon} /> },
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.bgColour}>
        <div className="container">
          <div className={styles.navContainer}>
            {/* CONTACT INFO */}
            <div className={styles.contactInfo}>
              <DrivaFooterLogo className={styles.footerLogo} />
              <div className={styles.contactItems}>
                <div>
                  <span className={styles.contactHeading}>Connect with us</span>
                  <a href="tel:1300755494" className={styles.contactItem}>
                    <div className={styles.svgContainer}>
                      <Phone className={styles.phoneIcon} />
                    </div>
                    <span className={styles.text}>1300 755 494</span>
                  </a>
                  <a href="mailto:contact@driva.com.au" className={styles.contactItem}>
                    <div className={styles.svgContainer}>
                      <Mail className={styles.mailIcon} />
                    </div>
                    <span className={styles.text}>contact@driva.com.au</span>
                  </a>
                  <CountrySwitcher />
                </div>
                <div>
                  <span className={styles.contactHeading}>Follow us</span>
                  <div className={styles.socials}>
                    {socials.map((item) => {
                      const { link, comp } = item;
                      return (
                        <Link key={link} to={link}>
                          {comp}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* NAV */}
            <FooterNavigation />
          </div>
          <FooterDisclaimer />
        </div>
      </div>
      <div className="container">
        <FooterLegal licenseNo={licenseNo?.text} abn={abn?.text} />
      </div>
    </footer>
  );
};

export default Footer;
