module.exports = [{
      plugin: require('../plugins/wm-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WBX285N","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"() => {\n          let queriesObj = {};\n          let userId = null;\n\n          try {\n            const queryStrings = (document && document.location && document.location.search) || {};\n            queriesObj = queryStrings ? Object.fromEntries(new URLSearchParams(queryStrings)) : {};\n            sessionStorage.setItem('queriesObj', JSON.stringify(queriesObj));\n          } catch (error) {\n            console.error('Error accessing session storage');\n          }\n\n          try {\n            userId = document.cookie.split(`; `).find((row) => row.startsWith(`user_id=`));\n            userId = userId ? userId.split(`user_id=`)[1] : null;\n\n            if (!userId) {\n              userId = Math.random().toString(36).substring(7);\n              const expires = 3600 * 24 * 60;\n              const cookie = `user_id=${userId}; path=/; domain=driva.com.au; max-age=${expires}`;\n              document.cookie = cookie;\n            }\n\n            window.userId = userId;\n          } catch (error) {\n            console.error('Error fetching user_id cookie');\n          }\n\n          return {\n            platform: `gatsby`,\n            user_id: userId,\n            ...queriesObj,\n          };\n        }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../plugins/wm-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.driva.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Online Car Finance | Your Perfect Car Loan Match | Driva","short_name":"Driva","description":"Online car finance made easy with Driva. We find the best car loan option for you. We give you the options, you make the choice.","start_url":"/","background_color":"#F8F7F7","theme_color":"#97EDCC","display":"standalone","icon":"src/images/driva-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"d64e1bc2d80fac05efab0bfc1dcb7b99"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["X-Frame-Options: DENY","X-XSS-Protection: 1; mode=block","X-Content-Type-Options: nosniff"]},"allPageHeaders":[],"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
