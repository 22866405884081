import React from 'react';
import { Section, Image } from 'components';
import { Link } from 'gatsby';
import * as styles from './styles.module.scss';

const TextImageOverlap = (props) => {
  const { data } = props;
  const { primary } = data;
  const { image, title, content, tagline, cta_text, cta_link } = primary;
  // CONTENT CHECKS
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const taglineHtml = tagline?.html;
  const hasCta = cta_text?.text && cta_link?.url;

  return (
    <Section sliceName="TextImageOverlap" containerClassName={styles.textImageOverlap}>
      {/* IMAGE */}
      {image?.url && (
        <span className={styles.imageContainer}>
          <Image image={image} className={styles.image} />
        </span>
      )}
      <div className={styles.textContainer}>
        {/* TITLE */}
        {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {/* CONTENT */}
        {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        {/* TAGLINE */}
        {taglineHtml && <div className={styles.tagline} dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
        {/* CTA */}
        {hasCta && (
          <Link to={cta_link.url} className={`button secondary ${styles.ctaButton || ''}`}>
            {cta_text?.text}
          </Link>
        )}
      </div>
    </Section>
  );
};

export default TextImageOverlap;
