import React from 'react';
import * as styles from './styles.module.scss';

const BalloonInput = ({ balloonAmount, setBalloonAmount, balloonPercentage, setBalloonPercentage }) => {
  const AUD = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumSignificantDigits: 5,
  });

  function transformCurrencyStringToNumber(currencyString) {
    const cleanedString = currencyString?.replace(/\$|,/g, '');
    return Number.isNaN(parseFloat(cleanedString)) ? 0 : parseFloat(cleanedString);
  }

  const handleBalloonAmountChange = (e) => {
    setBalloonPercentage('');
    const num = transformCurrencyStringToNumber(e.target.value);
    if (num >= 0) {
      setBalloonAmount(AUD.format(num));
    } else {
      setBalloonAmount('');
    }
  };
  const handleBalloonPercentChange = (e) => {
    setBalloonAmount('');
    const num = transformCurrencyStringToNumber(e.target.value);
    if (num >= 0 && num <= 100) {
      setBalloonPercentage(num);
    } else {
      setBalloonPercentage('');
    }
  };

  return (
    <div className={styles.residualWrapper}>
      <input
        className={styles.amountInput}
        value={balloonAmount}
        onChange={handleBalloonAmountChange}
        type="text"
        inputMode="numeric"
        maxLength="8"
        placeholder="$"
      />
      <p className={styles.or}>or</p>
      <div className={styles.wrapper}>
        <input
          type="text"
          inputMode="numeric"
          className={styles.percentInput}
          value={balloonPercentage}
          onChange={handleBalloonPercentChange}
          maxLength="3"
        />
        <div className={styles.percentVal}>%</div>
      </div>
    </div>
  );
};

export default BalloonInput;
