import React from 'react';
import { Link } from 'components';

const CtaLink = (props) => {
  const { cta_link: link, cta_text: cta, className, fallbackUrl, fallbackText } = props;
  const ctaUrl = link?.url || fallbackUrl;
  const ctaText = cta?.text || fallbackText;

  const hasCta = ctaUrl && ctaText;

  if (!hasCta) return null;

  return (
    <Link className={`button secondary ${className || ''}`} to={ctaUrl}>
      {ctaText}
    </Link>
  );
};

export default CtaLink;
