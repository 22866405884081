import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, Section, Image } from 'components';
import generatePath from '../../../helpers/generatePath';

import * as styles from './styles.module.scss';

const CityGrid = ({ currentPage }) => {
  // get data
  const citiesQuery = graphql`
    query {
      ...Cities
    }
  `;
  const { allPrismicPage } = useStaticQuery(citiesQuery);
  const { nodes } = allPrismicPage;

  const cityData = [];
  // format data
  nodes.forEach((city) => {
    const { data, uid } = city;
    const { prismic_reference: cityName, body } = data;
    const heroImage = body?.[0]?.primary?.image;
    if (uid !== currentPage) {
      cityData.push({ name: cityName?.text, img: heroImage, uid });
    }
  });

  return (
    <Section className={`city-grid ${styles.cityGrid}`}>
      <span className={styles.title}>We also cover these areas</span>
      <div className={styles.grid}>
        {cityData.map((city) => {
          const { name, img, uid } = city;
          if(uid !== 'programmatic-city')
          return (
            <div className={styles.cityCard}>
              {img?.url && (
                <Link to={`${generatePath(uid)}`}>
                  <Image className={styles.image} image={img} objectFit="COVER" />
                </Link>
              )}
              {name && (
                <Link to={`${generatePath(uid)}`}>
                  <span className={styles.cityName}>{name}</span>
                </Link>
              )}
              <Link className={styles.link} to={`${generatePath(uid)}`}>
                Learn more
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default CityGrid;
