import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './styles.module.scss';

const FooterDisclaimer = () => {
  const {
    prismicSettings: { data },
  } = useStaticQuery(settingsQuery);

  const { footer_disclaimer: footerDisclaimer } = data;
  const footerDisclaimerHtml = footerDisclaimer?.html;

  return (
    footerDisclaimerHtml && (
      <div dangerouslySetInnerHTML={{ __html: footerDisclaimerHtml }} className={styles.footerDisclaimer} />
    )
  );
};

export default FooterDisclaimer;

export const settingsQuery = graphql`
  query FooterDisclaimer {
    prismicSettings {
      data {
        footer_disclaimer {
          text
          html
        }
      }
    }
  }
`;
