import React from 'react';
import { Section, Image, TeslaText as Text, TeslaButton as Button } from 'components';
import { ReactComponent as Logo } from './logo.svg';

import * as styles from './styles.module.scss';

const Header = (props) => {
  const { data } = props;
  const { primary } = data;
  const { background_image: backgroundImage, title, cta_text: ctaText, cta_link: ctaLink } = primary;

  const hasBackgroundImage = backgroundImage?.url;
  const hasCta = ctaText?.text && ctaLink?.url;

  return (
    <Section sliceName="Header" className={styles.section} noContainer>
      <div className={styles.container}>
        <div className={styles.content}>
          <Text className={styles.title} {...title} />
          {hasCta && (
            <Button to={ctaLink.url} theme="yellow">
              {ctaText.text}
            </Button>
          )}
        </div>
      </div>
      {hasBackgroundImage && <Image image={backgroundImage} className={styles.image} />}
      <div className={styles.badge}>
        <div className={styles.logo}>
          <Logo />
        </div>
      </div>
    </Section>
  );
};

export default Header;
