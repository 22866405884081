// extracted by mini-css-extract-plugin
export var bomb = "styles-module--bomb--1dd72";
export var content = "styles-module--content--395b5";
export var errorMsg = "styles-module--errorMsg--d34cc";
export var fieldError = "styles-module--fieldError--45060";
export var form = "styles-module--form--4e5d1";
export var formButton = "styles-module--formButton--dfa80";
export var fullWidth = "styles-module--fullWidth--6c0d8";
export var label = "styles-module--label--3ed9a";
export var signUpForm = "styles-module--signUpForm--b64b7";
export var splitLeft = "styles-module--splitLeft--4c790";
export var splitRight = "styles-module--splitRight--7b04c";
export var title = "styles-module--title--949f4";
export var visible = "styles-module--visible--a54ec";