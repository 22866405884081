import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Section, OnVisible, Image } from 'components';
import { ReactComponent as GreenArrows } from 'images/green-arrows.svg';
import { ReactComponent as Chevron } from '../../images/down-chevron.svg';
import * as styles from './styles.module.scss';

const ServceBundles = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const [activeIndex, setActiveIndex] = useState();
  const [height, setHeight] = useState('100%');
  const [width, setWidth] = useState(null);
  const [breakPoint, setBreakPoint] = useState(null);

  // window listener
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  // set breakpoint state
  useEffect(() => {
    const widthRef = width || window.innerWidth;
    const breakpoint = 500;
    if (widthRef <= breakpoint) {
      setBreakPoint(true);
      setHeight(`${activeIndex ? '0px' : featuresRef.current.scrollHeight}`);
    } else {
      setBreakPoint(false);
      setHeight('100%');
    }
  }, [width]);

  // get feature height for toggle
  const featuresRef = useRef(null);

  // toggle features on mobile
  const handleOnClick = (event, index) => {
    event.preventDefault();
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
    setHeight(activeIndex === index ? '0px' : `${featuresRef.current.scrollHeight}px`);
  };

  const formattedItems = items.map((item) => {
    const {
      icon,
      description,
      title: itemTitle,
      property_four: prop4,
      property_one: prop1,
      property_three: prop3,
      property_two: prop2,
      second_value: value2,
      value_four: value4,
      value_one: value1,
      value_three: value3,
    } = item;
    return {
      title: itemTitle,
      description,
      icon,
      features: [
        { property: prop1?.html, value: value1?.html },
        { property: prop2?.html, value: value2?.html },
        { property: prop3?.html, value: value3?.html },
        { property: prop4?.html, value: value4?.html },
      ],
    };
  });

  return (
    <Section sliceName="ServceBundles" className={styles.section} containerClassName={styles.container}>
      <OnVisible className={styles.arrowContainer} visibleClassName={styles.visArrows}>
        <GreenArrows className={styles.greenArrows} />
      </OnVisible>
      {/* TITLES */}
      {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      {contentHtml && <div className={styles.description} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      {/* CARDS */}
      <OnVisible className={styles.cardsContainer} visibleClassName={styles.cardsVisible}>
        {formattedItems.map((item, index) => {
          const { title: cardTitle, description, icon, features } = item;
          const isActive = activeIndex === index;
          return (
            <Fragment key={cardTitle.text}>
              <OnVisible
                key={title?.text}
                className={`${styles.card} ${isActive ? styles.activeCard : ''}`}
                visibleClassName={styles.visibleCard}
              >
                {/* CARD TOP */}
                <div className={styles.cardTop}>
                  {icon?.url && <Image image={icon} className={styles.icon} />}
                  {cardTitle?.text && (
                    <div dangerouslySetInnerHTML={{ __html: cardTitle.html }} className={styles.cardTitle} />
                  )}
                  {description?.text && (
                    <div className={styles.cardDesc} dangerouslySetInnerHTML={{ __html: description.html }} />
                  )}
                </div>
                {/* FEATURES */}
                <div
                  className={`${styles.cardFeatures} ${isActive ? styles.featuresOpenMobile : ''}`}
                  ref={featuresRef}
                  style={{ maxHeight: `${height}` }}
                >
                  {features.map((feature) => {
                    const { property, value } = feature;
                    return (
                      <div className={styles.feature} key={property}>
                        <div dangerouslySetInnerHTML={{ __html: property }} className={styles.property} />
                        <div dangerouslySetInnerHTML={{ __html: value }} className={styles.value} />
                      </div>
                    );
                  })}
                </div>
                {/* COLLAPSED ON MOBILE */}
                <div
                  className={styles.mobileToggle}
                  onClick={(event) => handleOnClick(event, index)}
                  role="button"
                  tabIndex={index}
                >
                  <span>Learn {isActive ? 'less' : 'more'}</span>
                  <Chevron className={styles.chevron} />
                </div>
              </OnVisible>
            </Fragment>
          );
        })}
      </OnVisible>
    </Section>
  );
};

export default ServceBundles;
