import React from 'react';
import { Script } from 'gatsby';

const generateGTM = ({ id, environmentParamStr, dataLayerName, selfHostedOrigin }) => `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  '${selfHostedOrigin}/gtm.js?id='+i+dl+'${environmentParamStr}';f.parentNode.insertBefore(j,f);
  })(window,document,'script','${dataLayerName}', '${id}');`;

const generateGTMIframe = ({ id, environmentParamStr, selfHostedOrigin }) =>
  `<iframe src="${selfHostedOrigin}/ns.html?id=${id}${environmentParamStr}" height="0" width="0" style="display: none; visibility: hidden" aria-hidden="true"></iframe>`;

const generateDefaultDataLayer = (dataLayer, reporter, dataLayerName) => {
  let result = `window.${dataLayerName} = window.${dataLayerName} || [];`;

  if (dataLayer.type === `function`) {
    result += `window.${dataLayerName}.push((${dataLayer.value})());`;
  } else {
    if (dataLayer.type !== `object` || dataLayer.value.constructor !== Object) {
      reporter.panic(
        `Oops the plugin option "defaultDataLayer" should be a plain object. "${dataLayer}" is not valid.`
      );
    }

    result += `window.${dataLayerName}.push(${JSON.stringify(dataLayer.value)});`;
  }

  return `${result}`;
};

export const wrapPageElement = (
  { element, reporter },
  {
    id,
    includeInDevelopment = false,
    gtmAuth,
    gtmPreview,
    defaultDataLayer,
    dataLayerName = `dataLayer`,
    selfHostedOrigin = `https://www.googletagmanager.com`,
  }
) => {
  if (process.env.NODE_ENV === `production` || includeInDevelopment) {
    const gtmAuthQueriers = `&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;
    const environmentParamStr = gtmAuth && gtmPreview ? gtmAuthQueriers : ``;

    let defaultDataLayerCode = ``;

    if (defaultDataLayer) {
      defaultDataLayerCode = generateDefaultDataLayer(defaultDataLayer, reporter, dataLayerName);
    }

    selfHostedOrigin = selfHostedOrigin.replace(/\/$/, ``);

    return (
      <>
        <Script
          key="plugin-wm-tagmanager"
          dangerouslySetInnerHTML={{
            __html: `
            ${defaultDataLayerCode}
            ${generateGTM({
              id,
              environmentParamStr,
              dataLayerName,
              selfHostedOrigin,
            })}`,
          }}
        />
        {element}
        <noscript
          key="plugin-wm-tagmanager-iframe"
          dangerouslySetInnerHTML={{
            __html: generateGTMIframe({
              id,
              environmentParamStr,
              selfHostedOrigin,
            }),
          }}
        />
      </>
    );
  }
};
