import React from 'react';
import { Section, OnVisible, Image, Link } from 'components';
import * as styles from './styles.module.scss';

const TwoColsImage = (props) => {
  const { data } = props;
  const { items, primary } = data;
  const { title, content, cta_link: ctaLink, cta_text: ctaText } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  return (
    <Section
      className={`two-cols-image ${styles.section}`}
      containerClassName={styles.container}
      sliceName="TwoColsImage"
    >
      <div className={styles.textContainer}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      </div>
      <div className={styles.itemsContainer}>
        {items.map((item) => {
          const {
            align_image_left: alignLeft,
            content: itemContent,
            image,
            title: itemTitle,
            cta_link: itemCtaLink,
            cta_text: itemCtaText,
          } = item;
          return (
            <div
              className={`${alignLeft ? styles.alignImageLeft : ''} ${styles.itemGrid} two-cols-image-grid`}
              key={itemTitle.text}
            >
              <OnVisible className={styles.itemText} visibleClassName={styles.visItemText}>
                {itemTitle?.text && (
                  <div dangerouslySetInnerHTML={{ __html: itemTitle.html }} className={styles.itemTitle} />
                )}
                {itemContent?.text && (
                  <div dangerouslySetInnerHTML={{ __html: itemContent.html }} className={styles.itemContent} />
                )}
                {itemCtaLink?.url && itemCtaText?.text && (
                  <Link to={itemCtaLink.url} className={`button ${styles.itemButton}`}>
                    {itemCtaText.text}
                  </Link>
                )}
              </OnVisible>
              <OnVisible visibleClassName={styles.visibleImage} threshold="0.4">
                <div className={styles.imageContainer}>{image?.url && <Image image={image} />}</div>
              </OnVisible>
            </div>
          );
        })}
      </div>
      {hasCta && (
        <Link to={ctaLink.url} className={`button ${styles.button}`}>
          {ctaText.text}
        </Link>
      )}
    </Section>
  );
};

export default TwoColsImage;
