import React from 'react';
import { Link, TeslaButton } from 'components';
import { ReactComponent as Logo } from './logo.svg';

import * as styles from './styles.module.scss';

const TeslaHeader = ({ location, navLink }) => {
  const ctaData = {
    text: navLink?.text || 'Get Started',
    url: navLink?.url || 'https://dash.driva.com.au/welcome',
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <nav className={styles.actions}>
          <Link to="https://dash.driva.com.au/login" className={styles.login}>
            Login
          </Link>
          {ctaData && (
            <TeslaButton to={ctaData.url} className={styles.secondaryButton} theme="whiteOutline">
              {ctaData.text}
            </TeslaButton>
          )}
        </nav>
      </div>
    </header>
  );
};

export default TeslaHeader;
