import React from 'react';
import { CheckBox } from 'components';
import * as styles from './styles.module.scss';

const ButtonsBlock = ({
  fieldTitle,
  hasCheckBox = false,
  options,
  setSelectedOption,
  selectedOption,
  checkBoxActive,
  setCheckBoxActive,
  checkBoxField,
  isMobileRow,
}) => (
  <>
    <div className={styles.checkBoxRow}>
      <span className={styles.label}>{fieldTitle}</span>
      {hasCheckBox && (
        <CheckBox checkBoxActive={checkBoxActive} setCheckBoxActive={setCheckBoxActive} checkBoxField={checkBoxField} />
      )}
    </div>

    <div className={isMobileRow ? styles.displayNone : styles.creditScoreRow}>
      {options?.map((option, index) => (
        <button
          className={selectedOption?.title === option?.title ? styles.creditItemActive : styles.creditItem}
          onClick={() => setSelectedOption(option)}
          type="button"
          key={`${index + 1}`}
        >
          {option?.title}
        </button>
      ))}
    </div>

    {isMobileRow && (
      <div className={styles.creditScoreMobileRow}>
        {options?.map((option, index) => (
          <button
            className={selectedOption === option ? styles.creditItemActiveMobileRow : styles.creditItemMobileRow}
            onClick={() => setSelectedOption(option)}
            type="button"
            key={`${index + 1}`}
          >
            {option}
          </button>
        ))}
      </div>
    )}
  </>
);

export default ButtonsBlock;
