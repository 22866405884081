import * as React from 'react';
import queryString from 'query-string';
import { PrismicPreviewProvider } from 'components';

export const wrapRootElement = ({ element }) => <PrismicPreviewProvider element={element} />;

export const onRouteUpdate = ({ location, prevLocation }) => {
  const { search } = location;
  const incomingUtms = window.sessionStorage.getItem('queriesObj');
  const parsedIncomingUtms = incomingUtms ? JSON.parse(incomingUtms) : null;
  const hasIncomingUtms = parsedIncomingUtms && Object.keys(parsedIncomingUtms).length > 0;
  const hasExistingUtms = search?.length > 2;
  if (!hasIncomingUtms && hasExistingUtms) {
    const existingUtms = queryString.parse(search);
    console.log('EXISTINGUTMS', existingUtms);
    window.sessionStorage.setItem('queriesObj', JSON.stringify(existingUtms));
  }
};
