import React, { useState, useEffect } from 'react';
import { Section, FormField, OnVisible } from 'components';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import * as styles from './styles.module.scss';
import { ReactComponent as RedBomb } from '../../images/sign-up-graphic.svg';

const SignUpForm = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, content } = primary;
  // CONTENT CHECKS
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  // FORM DATA
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const { errors, dirtyFields } = formState;

  useEffect(
    () =>
      // Set submitting to false in clean up function
      () => {
        setSubmitting(false);
      },
    []
  );
  // INPUT FIELDS
  const fields = [
    {
      type: 'text',
      name: 'firstName',
      placeholder: 'Joe',
      label: 'First Name',
      className: styles.splitLeft,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your first name',
    },
    {
      type: 'text',
      name: 'lastName',
      placeholder: 'Bloggs',
      label: 'Last Name',
      className: styles.splitRight,
      defaultValue: '',
      validation: { required: true },
      validationMessage: 'Please enter your last name',
    },
    {
      type: 'email',
      name: 'email',
      placeholder: 'joe@bloggs.com',
      label: 'Email',
      className: styles.splitLeft,
      defaultValue: '',
      validation: { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
      validationMessage: 'Please enter a valid email',
    },
    {
      type: 'tel',
      name: 'phone',
      placeholder: '0412 345 678',
      label: 'Best contact number',
      className: styles.splitRight,
      defaultValue: '',
      validation: { required: true, minLength: 8 },
      validationMessage: 'Please enter a valid number',
    },
    {
      type: 'text',
      name: 'dealership',
      placeholder: 'Joe Bloggs',
      label: 'Dealership Name',
      defaultValue: '',
      className: styles.fullWidth,
      validation: { required: true },
      validationMessage: 'Please enter your last name',
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: '',
      className: styles.fullWidth,
      label: 'Comments',
      defaultValue: '',
    },
  ];

  const formId = 'nyXdpBF2';

  const onSubmit = async (values) => {
    setSubmitting(true);
    try {
      const url = `https://submit-form.com/${formId}`;
      const config = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(values),
      };
      const response = await fetch(url, config);
      // const json = await response.json()
      if (response.ok) {
        // return json
        return navigate('/thank-you/');
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSubmissionError('Oops something went wrong, please try again');
      setSubmitting(false);
    }
  };

  return (
    <Section sliceName="SignUpForm" id="sign-up">
      <div className={styles.signUpForm}>
        {/* TITLE */}
        {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {/* CONTENT */}
        {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        <hr />
        {/* BACKGROUND GRAPHIC */}
        <OnVisible visibleClassName={styles.visible}>
          <RedBomb className={styles.bomb} />
        </OnVisible>
        {submissionError && <p className={styles.errorMsg}>{submissionError}</p>}
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          {/* MAP FORM INPUT FIELDS */}
          {fields.map((field) => {
            const hasError = dirtyFields[field.name] && errors[field.name];
            return (
              <div key={field.label} className={[field.className]}>
                <span className={styles.label}>{field.label}</span>
                <FormField {...field} register={register} />
                <div className={`${styles.fieldError} ${hasError ? 'active' : 'inactive'}`}>
                  {hasError && <span>{field.validationMessage || 'This field is required'}</span>}
                </div>
              </div>
            );
          })}
          <button type="submit" className={`button ${styles.formButton}`} disabled={submitting}>
            {submitting ? 'Submitting' : 'Sign Up'}
          </button>
        </form>
      </div>
    </Section>
  );
};

export default SignUpForm;
