import React from 'react';
import { ReactComponent as WhiteTick } from '../../../images/white-tick.svg';
import { ReactComponent as InfoIcon } from '../../../images/info-icon-green.svg';

import * as styles from './styles.module.scss';

const CheckBox = ({ setCheckBoxActive, checkBoxActive, checkBoxField, clearState = false, tooltip }) => (
  <span className={styles.checkBoxItem}>
    <div
      className={styles.checkboxWrapper}
      onClick={() => {
        setCheckBoxActive(!checkBoxActive);
        if (!checkBoxActive && clearState) {
          clearState(20);
        }
      }}
      role="button"
      tabIndex={0}
    >
      <WhiteTick className={styles.whiteTick} />
      <input name="tickBox" type="checkbox" className={checkBoxActive ? styles.checkBoxActive : styles.checkBox} />
    </div>

    <div className={styles.row}>
      <span className={styles.checkBoxField}> {checkBoxField}</span>
      {tooltip?.length > 0 && (
        <div>
          <div className={styles.tooltipTop}>
            <InfoIcon className={styles.infoIcon} />
            <div className={styles.top}>
              <ul>
                {tooltip?.map((item, index) => (
                  <React.Fragment key={`${index + 1}`}>
                    <li className={styles.tooltipItem}>
                      <span className={styles.strong}>{item?.title}</span> {item?.content}
                    </li>
                  </React.Fragment>
                ))}
              </ul>
              <i />
            </div>
          </div>
        </div>
      )}
    </div>
  </span>
);

export default CheckBox;
