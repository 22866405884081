import React, { useState } from 'react';
import { Section, Image, Link } from 'components';
import { navigate } from 'gatsby';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import * as styles from './styles.module.scss';

const AnchorButtons = (props) => {
  const { data } = props;
  const { items } = data;

  const [activeIndex, setActiveIndex] = useState(false);

  const handleClick = (event, index, id, buttonType, link) => {
    event.preventDefault();
    if (buttonType === 'link') {
      navigate(link.url);
    }
    if (buttonType === 'anchor') {
      setActiveIndex(index);
      const anchorElement = document.getElementById(`${id}`);
      scrollIntoView(anchorElement, { behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Section sliceName="AnchorButtons" className={`${styles.anchorButtons} anchor-buttons`}>
      <div className={styles.buttonsContainer}>
        {items.map((button, index) => {
          const { anchor_id: id, button_link: link, button_text: text, icon, cta_text: ctaText } = button;
          const isActive = activeIndex === index ? styles.buttonActive : '';
          const buttonType = link?.url ? 'link' : 'anchor';
          return (
            <Link
              className={`${styles.button} ${isActive}`}
              to={`#${id?.text}` || link?.url}
              onClick={(event) => handleClick(event, index, id.text, buttonType, link)}
              key={text?.text}
            >
              {icon?.url && <Image className={styles.icon} image={icon} />}
              {text?.text && <div className={styles.buttonText} dangerouslySetInnerHTML={{ __html: text.html }} />}
              {ctaText?.text && <div className={styles.ctaText} dangerouslySetInnerHTML={{ __html: ctaText.html }} />}
            </Link>
          );
        })}
      </div>
    </Section>
  );
};

export default AnchorButtons;
