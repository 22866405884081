import React from 'react';
import { Section, Link, PartnerList, OnVisible } from 'components';
import { ReactComponent as ToolTip } from '../../images/info-icon.svg';
import { ReactComponent as PurpleArrows } from '../../images/purple-arrows.svg';
import * as styles from './styles.module.scss';

const PartnersGrid = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    supplementary_text: extraText,
    title,
    tool_tip: toolTip,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const extraTextHtml = extraText?.html;
  const toolTipHtml = toolTip?.html;
  const hasCta = ctaText?.text && ctaLink?.url;

  return (
    <Section sliceName="PartnersGrid" className={styles.partnersGrid}>
      {/* PURPLE ARROW GRAPHIC */}
      <OnVisible visibleClassName={styles.visible}>
        <PurpleArrows className={styles.purpleArrows} />
      </OnVisible>
      {/* TEXT CONTENT */}
      {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      <div className={styles.contentContainer}>
        {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        {toolTip?.text && (
          <div className={styles.tooltip}>
            <ToolTip className={styles.icon} />
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: toolTipHtml }} />
          </div>
        )}
      </div>
      {hasCta && (
        <Link to={ctaLink.url} className={`button primary ${styles.cta}`}>
          {ctaText?.text}
        </Link>
      )}
      {extraTextHtml && <div className={styles.extraText} dangerouslySetInnerHTML={{ __html: extraTextHtml }} />}
      {/* LOGO GRID */}
      <PartnerList />
    </Section>
  );
};

export default PartnersGrid;
