import React from 'react';
import * as styles from './styles.module.scss';

const Skeleton = ({ isSmaller }) => (
  <ul className={styles.oVerticalSpacingOVerticalSpacingL}>
    <li className={styles.blogPostOMedia}>
      <div className={styles.oMediaBody}>
        <div className={styles.oVerticalSpacing}>
          <p>
            <span
              className={styles.skeletonBox}
              style={{
                width: isSmaller && 80,
                height: isSmaller && 30,
                marginTop: isSmaller && -37,
                marginLeft: isSmaller && -90,
                display: isSmaller && 'none',
              }}
            />
          </p>
        </div>
      </div>
    </li>
  </ul>
);

export default Skeleton;
