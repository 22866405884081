import React, { useState } from 'react';
import Slider from 'react-rangeslider';

import * as styles from './styles.module.scss';
import 'react-rangeslider/lib/index.css';

const SliderComp = ({
  sliderState,
  setSliderState,
  setSliderValue,
  title,
  description,
  financeAmount,
  setVehiclePrice,
}) => {
  const isLessThanTenThousand = sliderState === 20000;

  const AUD = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumSignificantDigits: 5,
  });

  function transformCurrencyStringToNumber(currencyString) {
    const cleanedString = currencyString?.replace(/\$|,/g, '');
    return parseFloat(cleanedString);
  }

  const handleChange = (value) => {
    setSliderState(value);
    setVehiclePrice(value);
    setLoanValue(AUD.format(value));
  };

  const [loanValue, setLoanValue] = useState(AUD.format(financeAmount));

  const handleTextChange = (e) => {
    const val = e.target.value;
    const raw = transformCurrencyStringToNumber(val?.toString()) || 0;
    if (raw >= 0 && raw < 200000) {
      setLoanValue(AUD.format(raw));
      setSliderState(raw);
      setVehiclePrice(raw);
    }
  };

  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className={styles.wrapper}>
      <div className={styles.titlesContainer}>
        <div className={styles.inputWapper}>
          <span className={styles.inputLabel}>Loan amount:</span>
          <input type="text" value={loanValue} className={styles.inputField} onChange={handleTextChange} />
        </div>
      </div>
      <div className={styles.field}>
        <Slider
          className={styles.customSlider}
          min={2000}
          max={200000}
          value={sliderState || year}
          tooltip
          onChange={(e) => handleChange(e)}
          onChangeComplete={() => setSliderValue(sliderState)}
        />
      </div>
      <div className={styles.sliderMinMax}>
        <p>2K</p>
        <p>200K</p>
      </div>
    </div>
  );
};

export default SliderComp;
