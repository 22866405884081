import React, { useEffect } from 'react';
import { Link } from 'components';
import { ReactComponent as PersonalLoan } from '../../../images/personal-loan.svg';
import { ReactComponent as NovatedLease } from '../../../images/novated-lease.svg';
import { ReactComponent as Car } from '../../../images/car.svg';
import { ReactComponent as Question } from '../../../images/qa.svg';

import * as styles from './styles.module.scss';

const CallToAction = ({ type, className, centerAlign }) => {
  const renderType = {
    'Sign up now': <SignUp centerAlign={centerAlign} />,
    'Finance Type': <FinanceType />,
    'Get Started': <GetStarted />,
    'Get a Quote': <GetQuote />,
  };

  return <div className={`${styles.callToAction} ${className || ''}`}>{renderType[type]}</div>;
};

const SignUp = ({ centerAlign }) => (
  <div className={`${styles.signUpCta} ${centerAlign ? styles.centerAlign : ''}`}>
    <Link to="https://dash.driva.com.au/welcome" className={`button ${styles.button}`}>
      Sign up now
    </Link>
    <Link to="/faq" className={styles.ctaQuestion}>
      Have a question?
    </Link>
  </div>
);

const prefetchUrl = (url) => {
  const prefetchLink = document.createElement('link');
  prefetchLink.rel = 'prefetch';
  prefetchLink.href = url;
  document.head.appendChild(prefetchLink);

  return () => {
    document.head.removeChild(prefetchLink);
  };
};

const PrefetchLinks = () => {
  useEffect(() => {
    const cleanUp1 = prefetchUrl('https://dash.driva.com.au/welcome');
    const cleanUp2 = prefetchUrl('https://dash.driva.com.au/pl/loan');
    const cleanUp3 = prefetchUrl('https://forms.dva.is/to/S6qcVn#Start');

    return () => {
      cleanUp1();
      cleanUp2();
      cleanUp3();
    };
  }, []);

  return null;
};

const FinanceType = () => (
  <div className={styles.financeCta}>
    <PrefetchLinks /> 
    <div className={styles.textContainer}>
      <Question className={styles.question} />
      <span className={styles.text}>What type of finance are you after?</span>
    </div>
    <div className={styles.buttonsContainer}>
      {/* Vehicle */}
      <Link to="https://dash.driva.com.au/welcome" className={styles.option}>
        <Car className={styles.optionIcon} />
        <span>Vehicle Loan</span>
      </Link>
      {/* Personal */}
      <Link to="https://dash.driva.com.au/pl/loan" className={styles.option}>
        <PersonalLoan className={styles.optionIcon} />
        <span>Personal Loan</span>
      </Link>
      {/* Novated */}
      <Link to="https://forms.dva.is/to/S6qcVn" className={styles.option}>
        <NovatedLease className={styles.optionIcon} />
        <span>Novated Lease</span>
      </Link>
    </div>
  </div>
);

const GetStarted = () => (
  <Link to="https://dash.driva.com.au/welcome" className={`button ${styles.button}`}>
    Get started
  </Link>
);

const GetQuote = () => (
  <a href="#" className={`button ${styles.button}`}>
    Get started
  </a>
);
export default CallToAction;
