import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Image, Link, OnVisible } from 'components';
import { ReactComponent as ChatCircle } from '../../images/chat-circle.svg';
import { ReactComponent as DollarCircle } from '../../images/dollar-circle.svg';
import { ReactComponent as MailCircle } from '../../images/mail-circle.svg';

import * as styles from './styles.module.scss';

const ImageContactSteps = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    chat_description: chatDesc,
    chat_title: chatTitle,
    email_description: emailDesc,
    email_title: emailTitle,
    image,
    quote_description: quoteDesc,
    quote_title: quoteTitle,
    title,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const hasImage = image?.url;

  const items = [
    { title: chatTitle, desc: chatDesc, icon: <ChatCircle /> },
    { title: emailTitle, desc: emailDesc, icon: <MailCircle /> },
    { title: quoteTitle, desc: quoteDesc, icon: <DollarCircle /> },
  ];

  return (
    <Section
      sliceName="ImageContactSteps"
      className={`image-contact-steps ${styles.imageContactSteps}`}
      containerClassName={styles.container}
    >
      <div className={styles.leftCol}>
        {hasImage && <Image className={styles.image} image={image} objectFit="cover" />}
      </div>
      <div className={styles.rightCol}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {items.map((item, index) => {
          const { title: itemTitle, desc, icon } = item;
          return (
            <OnVisible
              className={styles.stepContainer}
              visibleClassName={styles.visibleStep}
              style={{ transitionDelay: `${0.2 * index}s` }}
              key={itemTitle.text}
            >
              {icon && <div className={styles.iconContainer}>{icon}</div>}
              <div className={styles.stepText}>
                {itemTitle?.text && (
                  <div className={styles.stepTitle} dangerouslySetInnerHTML={{ __html: itemTitle?.html }} />
                )}
                {desc?.text && <div className={styles.content} dangerouslySetInnerHTML={{ __html: desc?.html }} />}
              </div>
            </OnVisible>
          );
        })}
      </div>
    </Section>
  );
};

export default ImageContactSteps;
