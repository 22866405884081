import React from 'react';
import { PostCard } from 'components';
import * as styles from './styles.module.scss';

const PopularPosts = ({ popularPosts }) => (
  <div className={styles.popularPostsContainer}>
    <div className={styles.title}>Popular Posts</div>
    <div className={styles.postGrid}>
      {popularPosts.map((post, index) => (
        <PostCard key={`card_${index}`} post={post} readMore className={styles.cardContainer} />
      ))}
    </div>
  </div>
);

export default PopularPosts;
