import React from 'react';
import { Image, Link } from 'components';
import { ReactComponent as Linkedin } from 'images/linkedin.svg';
import * as styles from './styles.module.scss';

const AuthorCard = ({ author }) => {
  return (
    <div className={styles.authorCard}>
      {author?.image && <Image image={author?.image} className={styles.image} />}
      <div className={styles.textContainer}>
        {author?.name?.text && (
          <div dangerouslySetInnerHTML={{ __html: author?.name?.html }} className={styles.linkedAuthor} />
        )}
        {author?.bio?.text && <div dangerouslySetInnerHTML={{ __html: author?.bio?.html }} className={styles.bio} />}
        {author?.linkedin?.url && (
          <Link to={author?.linkedin?.url}>
            <Linkedin className={styles.linkedinIcon} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default AuthorCard;
