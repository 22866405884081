import React from 'react';
import { Section } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import CarLoanCalculator from '../../components/Common/Calculator/CarLoanCalculator';
import * as styles from './styles.module.scss';

const BlogCalculator = (props) => {
  const { data } = props;
  const { primary } = data;
  const { content, ...calculatorProps } = primary;

  const contentHtml = content?.html;

  const query = graphql`
    query {
      ...BlogCalculatorFragmentSettings
    }
  `;

  const staticData = useStaticQuery(query);
  const settingsData = staticData?.prismicSettings?.data || {};

  const { calculator_cta_link: calculatorCtaLink } = settingsData;

  return (
    <Section containerClassName={styles.container} sliceName="BlogCalculator">
      {/* <div className={styles.textContainer}> */}
      <div>
        {/* {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />} */}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      </div>
      <CarLoanCalculator {...calculatorProps} globalCtaLink={calculatorCtaLink} />
    </Section>
  );
};

export default BlogCalculator;
