import React, { useState } from 'react';
import { Section, Image, Link } from 'components';
import * as styles from './styles.module.scss';

const Product = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    icon,
    title,
    content,
    feature_content: featureContent,
    cta_text: ctaText,
    cta_link: ctaLink,
    mailto,
    disclaimer,
  } = primary;

  const [active, setActive] = useState(false);
  const handleOnClick = (event) => {
    event.preventDefault();
    if (!active) {
      setActive(true);
    }
    if (active) {
      setActive(false);
    }
  };

  return (
    <Section sliceName="Product" className={`product-slice ${styles.productSection}`}>
      <div className={`${styles.product} ${active ? styles.active : styles.inactive}`}>
        <div className={styles.productLeftCol} onClick={handleOnClick} role="button">
          {icon?.url && <Image className={styles.productIcon} image={icon} />}
          {title?.text && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title.html }} />}
          <div className={styles.accordionToggleContainer} aria-hidden="true">
            <div className={styles.accordionToggle} />
          </div>
        </div>
        <div className={styles.productRightCol}>
          {content?.text && <div className={styles.content} dangerouslySetInnerHTML={{ __html: content.html }} />}
          {featureContent?.text && (
            <div className={styles.featureContent} dangerouslySetInnerHTML={{ __html: featureContent.html }} />
          )}
          {mailto ? (
            <a href="mailto:hello@driva.com.au" className="button primary">
              {ctaText?.text}
            </a>
          ) : (
            <Link className="button primary" to={ctaLink?.url} target="_blank">
              {ctaText?.text}
            </Link>
          )}
          {disclaimer?.text && (
            <div className={styles.disclaimer} dangerouslySetInnerHTML={{ __html: disclaimer.html }} />
          )}
        </div>
      </div>
    </Section>
  );
};

export default Product;
