import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Link, OnVisible, Image } from 'components';

import * as styles from './styles.module.scss';

const LenderInfo = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    coapplicants,
    fees,
    fixed_rates_from: fixedRates,
    loan_amounts: loanAmounts,
    loan_terms: loanTerms,
    loan_types: loanTypes,
    logo,
    min_credit_score: creditScore,
    min_income: income,
  } = primary;

  const rowData = [
    { property: 'Fixed rates from:', value: fixedRates },
    { property: 'Loan amounts:', value: loanAmounts },
    { property: 'Loan terms:', value: loanTerms },
    { property: 'Loan types:', value: loanTypes },
    { property: 'Min. credit score:', value: creditScore },
    { property: 'Min. income:', value: income },
    { property: 'Co-applicants?', value: coapplicants },
    { property: 'Fees:', value: fees },
  ];

  return (
    <Section
      sliceName="LenderInfo"
      className={`lender-info ${styles.lenderInfo}`}
      containerClassName={styles.container}
    >
      <div className={styles.headerRow}>{logo?.url && <Image className={styles.logo} image={logo} />}</div>
      <div>
        {rowData.map((row, index) => {
          const { property, value } = row;
          return (
            <OnVisible
              visibleClassName={styles.visible}
              className={styles.row}
              style={{ transitionDelay: `${index * 0.05}s` }}
            >
              {value?.text && (
                <>
                  <span className={styles.rowProperty}>{property}</span>
                  <div className={styles.rowValue} dangerouslySetInnerHTML={{ __html: value.html }} />
                </>
              )}
            </OnVisible>
          );
        })}
      </div>
    </Section>
  );
};

export default LenderInfo;
