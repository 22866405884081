// extracted by mini-css-extract-plugin
export var bgColour = "styles-module--bgColour--4cbfa";
export var contactHeading = "styles-module--contactHeading--668a9";
export var contactInfo = "styles-module--contactInfo--a78e4";
export var contactItem = "styles-module--contactItem--7dcbb";
export var contactItems = "styles-module--contactItems--9525c";
export var footer = "styles-module--footer--e4089";
export var footerLogo = "styles-module--footerLogo--8d01f";
export var icon = "styles-module--icon--f9512";
export var logo = "styles-module--logo--5cde5";
export var mailIcon = "styles-module--mailIcon--f9076";
export var navContainer = "styles-module--navContainer--70b7a";
export var phoneIcon = "styles-module--phoneIcon--cd554";
export var socials = "styles-module--socials--c7b46";
export var svgContainer = "styles-module--svgContainer--58f69";
export var text = "styles-module--text--52036";