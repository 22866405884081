// extracted by mini-css-extract-plugin
export var bottomText = "styles-module--bottomText--9ce61";
export var button = "styles-module--button--d97f0";
export var canvas = "styles-module--canvas--d91ad";
export var canvasContainer = "styles-module--canvasContainer--54f8b";
export var container = "styles-module--container--6f01c";
export var content = "styles-module--content--c8bb5";
export var section = "styles-module--section--22528";
export var sequencer = "styles-module--sequencer--e985a";
export var title = "styles-module--title--4ebb3";
export var visibilitySensor = "styles-module--visibilitySensor--a408c";