// extracted by mini-css-extract-plugin
export var content = "styles-module--content--b1080";
export var image = "styles-module--image--9babb";
export var imageContainer = "styles-module--imageContainer--68172";
export var oneColVersion = "styles-module--oneColVersion--ba063";
export var productVariant = "styles-module--productVariant--8f055";
export var textBlock = "styles-module--textBlock--cac69";
export var textColumns = "styles-module--textColumns--62a87";
export var title = "styles-module--title--cf2aa";
export var visible = "styles-module--visible--703bb";
export var yellowLines = "styles-module--yellowLines--b8a62";