import React from 'react';
import { Link } from 'components';
import { ReactComponent as Facebook } from '../../../images/facebook.svg';
import { ReactComponent as Twitter } from '../../../images/twitter.svg';
import { ReactComponent as Linkedin } from '../../../images/linkedin.svg';
import * as styles from './styles.module.scss';

const SocialLinks = (props) => {
  const { title, location } = props;
  const { pathname } = location;
  const rootUrl = `https://www.driva.com.au${pathname}`;

  return (
    <div className={styles.socialLinks}>
      <div className={styles.title}>{title}</div>
      <div className={styles.iconsContainer}>
        <Link to={`https://www.facebook.com/sharer/sharer.php?u=${rootUrl}`} className={styles.social}>
          <Facebook />
        </Link>
        <Link to={`https://twitter.com/intent/tweet?url=${rootUrl}`} className={styles.social}>
          <Twitter />
        </Link>
        <Link to={`https://www.linkedin.com/shareArticle?mini=true&url=${rootUrl}`} className={styles.social}>
          <Linkedin />
        </Link>
      </div>
    </div>
  );
};

export default SocialLinks;
