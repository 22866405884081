import React, { useState, useEffect } from 'react';
import { Link } from 'components';
import { ReactComponent as HeaderSvg } from '../../../images/landing-page-header.svg';
import * as styles from './styles.module.scss';

const BlogHeader = ({ title, author, timestamp, categoryPage, landingPage, subtitle, category, dataRaw }) => {
  const titleHtml = title?.html;
  const categorySlug = category?.uid;
  // Depending on blog post or category page
  const categoryText = categoryPage ? category?.text : category?.document?.data?.category_name?.text;

  // On landing page or category page
  const subtitleHtml = subtitle?.html;

  // time to read
  const [minsToRead, setMinsToRead] = useState(0);
  useEffect(() => {
    const dataString = JSON.stringify(dataRaw);
    const wordsPerMinute = 200;
    const textLength = dataString && dataString.split(' ').length; // Split by words
    if (textLength > 0) {
      setMinsToRead(Math.ceil(textLength / wordsPerMinute));
    }
  }, [dataRaw]);

  const breadcrumbs = [
    {
      name: 'Homepage',
      uid: '',
    },
    {
      name: 'Blog',
      uid: `/blog/`,
    },
    {
      name: categoryText,
      uid: `/blog/${categorySlug}`,
    },
  ];

  const schemaBreadcrumbs = `
  <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "name": "Breadcrumbs",
      "description": "Breadcrumbs list",
      "itemListElement":[
        ${breadcrumbs.map(
          (breadcrumb, index) => `{
            "@type": "ListItem",
            "position": ${index + 1},
            "name": "${breadcrumb.name}",
            "item": "${process.env.GATSBY_SITE_URL}${breadcrumb.uid}"
          }`
        )}
      ]
    }
  </script>`;

  return (
    <header className={styles.blogHeader}>
      <div className={`container ${landingPage && styles.landingPage}`}>
        {landingPage && <HeaderSvg className={styles.image} />}
        <div>
          {/* BREAD CRUMBS */}
          {!landingPage && (
            <div className={`${styles.breadcrumbContainer} ${categoryPage && styles.categoryBreadcrumbContainer}`}>
              <Link to="/blog/">Blog</Link>
              <span> / {categoryPage && categoryText}</span>
              {/* On Category Page remove link as already on right page */}
              {!categoryPage && (
                <Link to={`/blog/${categorySlug}`} className={styles.breadcrumb}>
                  {categoryText}
                </Link>
              )}
            </div>
          )}
          {/* TITLE */}
          {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {landingPage && subtitleHtml && (
            <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitleHtml }} />
          )}
          {/* AUTHOR / TIMESTAMP */}
          {!landingPage && (
            <div className={styles.byLine}>
              <span className={styles.authorDetails}>{`Published ${timestamp}
                ${author?.text && `by ${author.text}`}`}</span>
              <span className={styles.hyphen}> - </span>
              {minsToRead !== 0 && <span className={styles.readtime}>{minsToRead} min read</span>}
            </div>
          )}
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: schemaBreadcrumbs }} />
    </header>
  );
};

export default BlogHeader;
