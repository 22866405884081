import React, { useState, useEffect } from 'react';
import { SocialLinks, Section, SideBarCta } from 'components';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { ReactComponent as Arrow } from '../../../images/arrow-in-circle.svg';
import * as styles from './styles.module.scss';

const TableOfContents = ({ chapterTitles, className, cta, location }) => {
  // scroll to anchor id
  const handleClick = (e, id) => {
    e.preventDefault();
    const anchorElement = document.getElementById(id);
    scrollIntoView(anchorElement, { behavior: 'smooth', block: 'start' });
  };

  // get matching ID from title
  const getIdFromTitle = (title) => (title ? title.replace(/\s+/g, '-').toLowerCase() : '');
  const links = chapterTitles.map((label) => ({ id: getIdFromTitle(label), label }));
  // state for active links/titles
  const [active, setActive] = useState(null);
  const [linkElements, setLinkElements] = useState(links);
  // offset scroll to
  const getLinkOffsets = () => {
    // Loop over sidebar items and get the offsetTop of the related divs
    chapterTitles.forEach((anchorPoint, index) => {
      const anchorId = getIdFromTitle(anchorPoint);
      const linkElement = document.getElementById(anchorId);
      const offset = (window?.pageYOffset || 0) + (linkElement?.getBoundingClientRect()?.top || 0);
      linkElements[index].offset = linkElement ? offset : 9999;
    });
    // Save sideLinks with offset keys to state
    setLinkElements(linkElements);
    setActive(active || linkElements[0]);
  };
  // change state and styles on scroll
  const scrollListener = () => {
    const scrollPosition = window.pageYOffset;
    const top = scrollPosition;
    const bottomOfPage = scrollPosition + window.innerHeight === document.documentElement.scrollHeight;
    const lastItem = linkElements[linkElements.length - 1];

    const getActive = (link, index, self) => {
      // First sidebar link selected when scroll is less than first item offset
      if (index === 0 && top < link.offset) return true;
      // Last sidebar link selected when check reaches end of array
      if (!self[index + 1]) return true;
      // Check whether top falls between active and next item's offset
      return top >= link.offset && top <= self[index + 1].offset;
    };

    const scrollActive = bottomOfPage ? lastItem : linkElements.find(getActive);

    if (scrollActive && scrollActive !== active) {
      setActive(scrollActive);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    // Fetch offsetTop values for elements matching sidebar ids
    getLinkOffsets();
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <Section className={`${styles.sidebarSection} ${className || ''}`} noContainer>
      <SocialLinks title="Content Chapters" location={location}/>
      <div className={styles.container}>
        {chapterTitles.map((title) => {
          const anchorId = getIdFromTitle(title);
          const isActive = active && active.label === title;
          return (
            <a
              className={`${styles.chapterTitleContainer} ${isActive ? styles.activeTitle : ''}`}
              href={`#${anchorId}`}
              onClick={(event) => handleClick(event, anchorId)}
              key={anchorId}
            >
              <span className={styles.chapterTitle}>{title}</span>
              <Arrow className={styles.arrowIcon} />
            </a>
          );
        })}
      </div>
      <SideBarCta ctaData={cta} />
    </Section>
  );
};

export default TableOfContents;
