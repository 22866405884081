import React, { createContext, useState, useEffect } from 'react';
import queryString from 'query-string';
import { CookiesProvider } from 'react-cookie';
import useSessionStorage from '../hooks/useSessionStorage';

const defaultSiteContext = {
  queries: null,
};

const SiteContext = createContext(defaultSiteContext);

export const SiteProvider = ({ children }) => {
  const [queries, setQueries] = useState(null);

  const sessionStorage = useSessionStorage('session');

  useEffect(() => {
    const utms = sessionStorage.getItem('queriesObj');
    const parsedUtms = utms ? JSON.parse(utms) : null;
    const hasUtms = parsedUtms && Object.keys(parsedUtms).length > 0;
    if (hasUtms) {
      const stringifiedUtms = queryString.stringify(parsedUtms);
      setQueries(stringifiedUtms);
    }
  }, [sessionStorage]);

  return (
    <SiteContext.Provider
      value={{
        ...defaultSiteContext,
        queries,
      }}
    >
      <CookiesProvider defaultSetOptions={{ path: '/' }}>{children}</CookiesProvider>
    </SiteContext.Provider>
  );
};

export const SiteConsumer = SiteContext.Consumer;

export default SiteContext;
