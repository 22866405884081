import React from 'react';
import * as styles from './styles.module.scss';

const Tooltip = ({ hasTitle = false, items, isSmallerToolTip = false }) => (
  <>
    <div className={styles.tooltip}>
      <div className={styles.toolTipRow}>
        {hasTitle && (
          <div>
            Not sure?
            <span className={styles.toolTipCta}>Here’s our guide</span>
          </div>
        )}
      </div>

      {items?.length > 0 && !isSmallerToolTip && (
        <div className={styles.toolTipWrapperMobile}>
          <div className={styles.center}>
            <div className={styles.textContent}>
              <ul>
                {items.map((item, index) => (
                  <React.Fragment key={`${index + 1}`}>
                    <li className={styles.tooltipItem}>
                      <span className={styles.strong}>{item?.title}</span> {item?.content}
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
            <i />
          </div>
        </div>
      )}
      {items?.length > 0 && !isSmallerToolTip && (
        <>
          <div className={styles.toolTipWrapper}>
            <div className={isSmallerToolTip ? styles.top : styles.right}>
              <div className={styles.textContent}>
                <ul>
                  {items.map((item, index) => (
                    <React.Fragment key={`${index + 1}`}>
                      <li className={styles.tooltipItem}>
                        <span className={styles.strong}>{item?.title}</span> {item?.content}
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
              {!isSmallerToolTip && <i />}
            </div>
          </div>
        </>
      )}
    </div>
  </>
);

export default Tooltip;
