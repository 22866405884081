import React, { useState, useEffect } from 'react';
import { PostCard, Section, Link } from 'components';
import * as styles from './styles.module.scss';

const CategoryThreeCardCol = ({ categoryPosts, title, noViewAll, className = '', isPaginated, displayViewAllVal }) => {
  // state
  const [postsToShow, setPostsToShow] = useState(6);
  const [reachedEnd, setReachedEnd] = useState(false);

  const totalPosts = categoryPosts?.length;

  const handleLoadMore = (event) => {
    event.preventDefault();
    setPostsToShow(postsToShow + 6);
    if (postsToShow + 6 > totalPosts) {
      setReachedEnd(true);
    }
  };

  // useEffect(() => {
  //   setPostsToShow(paginationAmount);
  // }, [paginationAmount]);

  if (!categoryPosts || categoryPosts.length < 1) return null;
  // data formatting
  const categorySlug = categoryPosts[0]?.data?.category?.uid;
  const mobileCategory = title?.toLowerCase();

  return (
    <Section className={`three-cols-cards ${styles.threeColCards} ${className}`}>
      {isPaginated && (
        <div className={styles.postCount}>
          Showing {reachedEnd || postsToShow > categoryPosts.length ? categoryPosts.length + 3 : postsToShow + 3} of{' '}
          {categoryPosts.length + 3} posts
        </div>
      )}
      <div className={styles.titleButtonContainer}>
        {title && <span className={styles.title}>{title}</span>}
        <div style={{ display: displayViewAllVal }}>
          {!noViewAll && (
            <Link to={`/blog/${categorySlug}/`} theme="secondary" className={`button ${styles.viewMoreButton}`}>
              View all posts
            </Link>
          )}
        </div>
      </div>
      <div className={styles.postGrid}>
        {postsToShow && categoryPosts.length > 0
          ? categoryPosts
              .slice(0, postsToShow)
              .map((post, index) => (
                <PostCard key={`card_${index}`} post={post} readMore className={styles.cardContainer} isThreeCols />
              ))
          : categoryPosts.map((post, index) => (
              <PostCard key={`card_${index}`} post={post} readMore className={styles.cardContainer} isThreeCols />
            ))}
      </div>
      {!noViewAll && (
        <Link
          to={`/blog/${categorySlug}/`}
          className={styles.mobileViewMore}
        >{`View all ${mobileCategory} articles`}</Link>
      )}
      {isPaginated && categoryPosts.length > postsToShow && !reachedEnd && (
        <div className={styles.loadMoreButton}>
          <button type="button" onClick={handleLoadMore} className={`button ${styles.button}`}>
            {`Load More (+${categoryPosts.length - postsToShow})`}
          </button>
        </div>
      )}
    </Section>
  );
};
export default CategoryThreeCardCol;
