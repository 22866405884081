import React, { useState, useEffect, useCallback } from 'react';
import { SliderComp, TypedInput, ButtonsBlock, Results, BalloonInput } from 'components';
import { CountUp } from 'countup.js';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import PartnerTicker from '../PartnerTicker';
import {
  loanTermOptions,
  balloonPaymentOptions,
  repaymentFrequencyOptions,
  balloonPaymentTooltip,
} from './calculatorConfig';
import { calculatorTypeHelper } from './helpers';

import useDebounce from '../../../hooks/useDebounce';

import * as styles from './styles.module.scss';

const today = new Date();
const year = today.getFullYear();

const CarLoanCalculator = (props) => {
  const { calculator_type: calculatorType, cta_text: ctaTextObj, cta_link: ctaLinkObj, globalCtaLink } = props;
  const ctaLink = globalCtaLink?.url || ctaLinkObj?.url;
  const ctaText = ctaTextObj?.text;
  // Input
  const [vehiclePrice, setVehiclePrice] = useState(20000);
  const [depositAmount, setDepositAmount] = useState(0);
  const [loanTerm, setLoanTerm] = useState(5);
  const [balloonPayment, setBalloonPayment] = useState(1);
  const [balloonAmount, setBalloonAmount] = useState('');
  const [balloonPercentage, setBalloonPercentage] = useState('');

  const [sliderValue, setSliderValue] = useState(20000);
  const [creditScore, setCreditScore] = useState({ title: 'Excellent', value: 'excellent' });
  const [equifaxScore, setEquifaxScore] = useState(null);
  const [financeAmount, setFinanceAmount] = useState(false);
  // Conditionals
  const [repaymentFrequency, setRepaymentFrequency] = useState('Weekly');
  const [knownScore, setKnownScore] = useState(false);
  const [isBalloonPayment, setIsBalloonPayment] = useState(false);
  const [slider, setSlider] = useState(20000);
  const [formActive, setFormActive] = useState(false);
  const [invalidData, setInvalidData] = useState(false);

  // Return Data
  const [returnData, setReturnData] = useState(null);

  // Animations
  const [animate, setAnimate] = useState(false);

  const amountToPost = Number(vehiclePrice) - Number(depositAmount);

  // error handling
  const IS_BAD_RESPONSE = invalidData;
  const vehicleIsLessThanDeposit = Number(vehiclePrice) < Number(depositAmount);

  const decimalHelper = (item) => {
    const itemSplit = item?.toString()?.split('.');
    const itemOneDecimalPlace = itemSplit && itemSplit[1]?.length === 1;
    const itemNoDecimalPlace = itemSplit && !itemSplit[1];

    if (itemNoDecimalPlace) {
      return `${item}.00%`;
    }
    if (itemOneDecimalPlace) {
      return `${item}0%`;
    }
    return `${item.toFixed(2)}%`;
  };

  if (!knownScore && !creditScore) {
    setCreditScore('Excellent');
  }

  function transformCurrencyStringToNumber(currencyString) {
    const cleanedString = currencyString?.replace(/\$|,/g, '');
    return Number.isNaN(parseFloat(cleanedString)) ? 0 : parseFloat(cleanedString);
  }

  function getBalloonAmount() {
    const cleanBalloonAmount = !Number.isNaN(balloonAmount) ? transformCurrencyStringToNumber(balloonAmount) : 0;
    const cleanBalloonPercent = !Number.isNaN(balloonPercentage) ? balloonPercentage : 0;

    if (cleanBalloonAmount > 0) {
      return cleanBalloonAmount;
    }
    if (cleanBalloonPercent > 0) {
      return (amountToPost * cleanBalloonPercent) / 100;
    }
    return 0;
  }

  const items = {
    loan: {
      amount: amountToPost <= 0 ? 1 : amountToPost || 1,
      deposit: depositAmount <= 0 ? 1 : Number(depositAmount) || 1,
      term: Number(loanTerm) || 1,
      purpose: 'purchase',
      balloon: isBalloonPayment ? getBalloonAmount() : 0,
    },
    extra: {
      vehicleYear: 2020,
      assetType: calculatorTypeHelper(calculatorType),
    },
    creditScore: {
      band: knownScore && equifaxScore ? 'great' : creditScore?.value,
      equifaxScore: knownScore && equifaxScore ? Number(equifaxScore) : 0,
      vedaScore: knownScore && equifaxScore ? Number(equifaxScore) : 0,
    },
  };

  // ON VIEW CONFIG
  const [ref, inView] = useInView({
    threshold: 0,
    delay: 200,
    triggerOnce: false,
  });
  const [secondaryInView, inViewSecondary] = useInView({
    threshold: 0,
    delay: 200,
    triggerOnce: false,
  });

  const fetchData = useCallback(async (values) => {
    try {
      const response = await axios.post(`${process.env.GATSBY_SITE_URL}/api/calculator`, values);

      setReturnData(response?.data);
    } catch (e) {
      console.error('Error: ', e.message);
    }
  }, []);

  useEffect(() => {
    fetchData(items);
    setAnimate(true);
    setInvalidData(false);
  }, [
    useDebounce(vehiclePrice, 700),
    useDebounce(depositAmount, 700),
    useDebounce(creditScore, 700),
    useDebounce(equifaxScore, 700),
    useDebounce(balloonAmount, 700),
    useDebounce(balloonPercentage, 700),

    sliderValue,
    loanTerm,
    repaymentFrequency,
    knownScore,
    balloonPayment,
  ]);

  useEffect(() => {
    setAnimate(false);

    if (returnData?.error) {
      setInvalidData(true);
    } else {
      setInvalidData(false);
    }

    if (returnData?.term) {
      setLoanTerm(returnData?.term);
    }

    setFinanceAmount(amountToPost);
  }, [returnData]);

  // Speeedometer Animation
  useEffect(() => {
    const options = {
      startVal: financeAmount - 25,
    };

    const financeTarget = document.getElementById('countUp');
    new CountUp(financeTarget, financeAmount, options).start();
  }, [financeAmount]);

  useEffect(() => {
    const paymentAmount = returnData ? returnData[repaymentFrequency?.toLowerCase()] : 0;
    const optionsRepayment = {
      startVal: paymentAmount - 25,
    };

    const repaymentTarget = document.getElementById('repayment');
    new CountUp(repaymentTarget, paymentAmount, optionsRepayment).start();

    const repaymentTargetMobile = document.getElementById('repaymentMobile');
    new CountUp(repaymentTargetMobile, paymentAmount, optionsRepayment).start();
  }, [returnData?.weekly, repaymentFrequency]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.calculator}>
          <div className={styles.columnOne}>
            <SliderComp
              sliderState={slider}
              setSliderState={setSlider}
              setSliderValue={setSliderValue}
              title="Loan amount"
              description=""
              financeAmount={slider}
              setVehiclePrice={setVehiclePrice}
            />
            <div className={styles.stickyWrapper}>
              {/* Years Block */}
              <p className={styles.inputLabel}>Loan term (years)</p>
              <ButtonsBlock
                options={loanTermOptions}
                setSelectedOption={setLoanTerm}
                selectedOption={loanTerm}
                isMobileRow
              />

              <p className={styles.inputLabel}>Repayments</p>
              <ButtonsBlock
                options={repaymentFrequencyOptions}
                setSelectedOption={setRepaymentFrequency}
                selectedOption={repaymentFrequency}
                isMobileRow
              />
              <button
                onClick={() => (isBalloonPayment ? setIsBalloonPayment(false) : setIsBalloonPayment(true))}
                type="button"
                className={styles.balloonBtn}
              >
                <span className={styles.buttonIcon}>{isBalloonPayment ? <>&minus;</> : '+'}</span> Residual / balloon
              </button>
              {isBalloonPayment && (
                <BalloonInput
                  balloonAmount={balloonAmount}
                  setBalloonAmount={setBalloonAmount}
                  balloonPercentage={balloonPercentage}
                  setBalloonPercentage={setBalloonPercentage}
                />
              )}
            </div>
          </div>
          <div className={styles.columnTwo} ref={secondaryInView}>
            <Results
              // financeAmount={financeAmount}
              repaymentFrequency={repaymentFrequency}
              returnData={returnData}
              animate={animate}
              formActive={formActive}
              // repaymentFrequencyOptions={repaymentFrequencyOptions}
              setRepaymentFrequency={setRepaymentFrequency}
              isBadResponse={IS_BAD_RESPONSE}
              decimalHelper={decimalHelper}
              ctaLink={ctaLink}
              ctaText={ctaText}
            />
          </div>
        </div>
        <PartnerTicker />
      </div>
    </>
  );
};

export default CarLoanCalculator;
