import React from 'react';
import { Section, Image, Link } from 'components';
import MarqueeText from 'react-marquee-text-component';
import * as styles from './styles.module.scss';

const Tickertape = (props) => {
  const { data } = props;
  const { items, primary } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, title } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  const logos = (
    <>
      {items.map((item) => (
        <Image key={item.logo.url} image={item.logo} className={styles.logo} loading="eager" />
      ))}
    </>
  );

  return (
    <Section sliceName="Tickertape" className={styles.section} noContainer>
      <div className={styles.textContainer}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      </div>
      <MarqueeText className="logo-ticker" text={logos} duration={30} repeat={3} />
      {hasCta && (
        <Link to={ctaLink.url} className={`button ${styles.button}`}>
          {ctaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default Tickertape;
