import React from 'react';
import * as styles from './styles.module.scss';

const LoadingSpinner = () => (
  <div className={styles.loadingSpinner}>
    <div className={styles.dualRing} />
  </div>
);

export default LoadingSpinner;
