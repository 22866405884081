import React, { useState, useEffect } from 'react';
import { Section, PostCard, Button } from 'components';
import * as styles from './styles.module.scss';

const CategoryGrid = ({ data }) => {
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const { nodes } = data;

  useEffect(() => {
    setLoadedPosts(nodes.slice(0, 15));
  }, []);

  useEffect(() => {
    setLoadMoreCount((prevCount) => {
      if (nodes.length - loadedPosts.length > 15) {
        setLoadMoreCount(15);
      }
      setLoadMoreCount(nodes.length - loadedPosts.length);
    });
  }, [loadedPosts]);

  const handleLoadMore = () => {
    let startEndSliceIndex = 15;
    setLoadedPosts(nodes.slice(0, (startEndSliceIndex += 15)));
  };

  return (
    <Section>
      <div className={styles.postCount}>
        Showing {loadedPosts.length} of {nodes.length} posts
      </div>
      <div className={styles.categoryGrid}>
        {nodes &&
          loadedPosts.map((node, index) => (
            <PostCard
              key={node?.data?.title?.text}
              className={index === 0 ? styles.categoryCardHero : null}
              post={node}
              showExcerpt={index === 0}
              readMore
            />
          ))}
      </div>
      {loadMoreCount > 0 && (
        <div className={styles.buttonContainer}>
          <a onClick={handleLoadMore} className="button white" href="#loadmore">
            Load More (+{loadMoreCount} posts)
          </a>
        </div>
      )}
    </Section>
  );
};

export default CategoryGrid;
