import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Ticker from 'react-ticker';
import MarqueeText from 'react-marquee-text-component';
import { Image } from 'components';
import * as styles from './styles.module.scss';

const PartnerTicker = () => {
  const partnersQuery = graphql`
    query {
      ...GlobalPartnerData
    }
  `;

  const {
    prismicSettings: { data: partnerData },
  } = useStaticQuery(partnersQuery);

  const { partners } = partnerData;

  const partnerLogos = (
    <div className={styles.logoWrapper}>
      {partners?.map(
        (item, idx) =>
          idx < 6 && <Image key={item?.logo?.url} image={item?.logo} className={styles.logo} loading="eager" />
      )}
    </div>
  );

  return (
    <div className={styles.ticker_wrap}>
      <Ticker>{() => partnerLogos}</Ticker>
    </div>
  );
};

export default PartnerTicker;
