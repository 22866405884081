import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const SideBarCta = ({ ctaData }) => {
  const {
    breakoutContent: content,
    breakoutCtaLink: ctaLink,
    breakoutCtaText: ctaText,
    breakoutTitle: title,
  } = ctaData;

  return (
    <div className={styles.ctaContainer}>
      {title?.text && <span className={styles.title}>{title.text}</span>}
      {content?.text && <span className={styles.content}>{content.text}</span>}
      {ctaText?.text && (
        <Link to={ctaLink?.url} className={`button ${styles.button}`}>
          {ctaText?.text}
        </Link>
      )}
    </div>
  );
};

export default SideBarCta;
