// extracted by mini-css-extract-plugin
export var alignImageLeft = "styles-module--alignImageLeft--e5e94";
export var centered = "styles-module--centered--15472";
export var cream = "styles-module--cream--4af33";
export var cta = "styles-module--cta--bf3d8";
export var ctaButton = "styles-module--ctaButton--25020";
export var green = "styles-module--green--fd0d8";
export var image = "styles-module--image--aa322";
export var imageContainer = "styles-module--imageContainer--22f45";
export var personVersion = "styles-module--personVersion--d05f8";
export var purple = "styles-module--purple--2c2a3";
export var textContainer = "styles-module--textContainer--749bc";
export var title = "styles-module--title--90dea";
export var visible = "styles-module--visible--39357";
export var white = "styles-module--white--1aad0";
export var yellowArrows = "styles-module--yellowArrows--576e7";