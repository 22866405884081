import React from 'react';
import { Section, TrustPilotReviews, OnVisible } from 'components';
import { ReactComponent as WhiteHeart } from '../../images/white-heart.svg';
import { ReactComponent as GreenHeart } from '../../images/green-heart.svg';

import * as styles from './styles.module.scss';

const TrustPilot = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, content, green_background: greenBg } = primary;
  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const backgroundColor = greenBg ? styles.greenBg : '';

  return (
    <Section sliceName="TrustPilot" className={`${backgroundColor} ${styles.TrustPilot}`}>
      {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      <OnVisible visibleClassName={styles.visible}>
        {greenBg ? <WhiteHeart className={styles.heart} /> : <GreenHeart className={styles.heart} />}
      </OnVisible>
      <div className={styles.reviewsContainer}>
        <TrustPilotReviews />
      </div>
    </Section>
  );
};

export default TrustPilot;
