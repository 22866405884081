import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const IframeEmbed = (props) => {
  const { data } = props;
  const { primary } = data;
  const { iframe_url: iframe } = primary;

  if (!iframe?.url) return null;

  return (
    <Section sliceName="iframeEmbed" className={styles.iframe} containerClassName={styles.container}>
      <iframe src={iframe.url} id="bankstatements" title={iframe.url} />
    </Section>
  );
};

export default IframeEmbed;
