// extracted by mini-css-extract-plugin
export var container = "styles-module--container--c9bc6";
export var content = "styles-module--content--e8d0d";
export var contentTop = "styles-module--contentTop--e1f5c";
export var divider = "styles-module--divider--a3cbb";
export var imageContainer = "styles-module--imageContainer--4542d";
export var leftImage = "styles-module--leftImage--a9b7e";
export var rightImage = "styles-module--rightImage--64a0f";
export var section = "styles-module--section--83192";
export var step = "styles-module--step--5eba8";
export var steps = "styles-module--steps--16f57";