import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import {
  Layout,
  Slice,
  Image,
  ScrollBar,
  BlogHeader,
  BlogPostSidebarShortForm,
  TableOfContents,
  CategoryThreeCardCol,
  BlogFooterCta,
  AuthorCard,
  PopupCta,
} from 'components';
import generatePath from '../helpers/generatePath';

import * as styles from './blog_post.module.scss';

const BlogPost = ({ location, data }) => {
  const { prismicBlogPost, relatedArticles, recentPosts, settings } = data;
  const {
    data: blogPostData,
    uid,
    dataRaw,
    first_publication_date: publishDate,
    last_publication_date: lastModifiedDate,
  } = prismicBlogPost;
  const { nodes: relatedArticlesNodes } = relatedArticles;
  const { nodes: recentPostsNodes } = recentPosts;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    ogImage,
    author,
    title,
    timestamp,
    type,
    category,
    hero_image: heroImage,
    linked_author: linkedAuthor,
    product_type: productType,
    name,
    brand,
    image,
    item_description: itemDescription,
    review_description: reviewDescription,
    rating,
  } = blogPostData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
  };

  const {
    data: {
      blog_popular_posts_breakout_background_colour: breakoutBg,
      blog_popular_posts_breakout_title: breakoutTitle,
      blog_popular_posts_breakout_content: breakoutContent,
      blog_popular_posts_breakout_cta_link: breakoutCtaLink,
      blog_popular_posts_breakout_cta_text: breakoutCtaText,
      blog_navigation_cta_text: navCtaText,
      blog_navigation_cta_link: navCtaLink,
    },
  } = settings;

  const ctaData = {
    breakoutBg,
    breakoutTitle,
    breakoutContent,
    breakoutCtaLink,
    breakoutCtaText,
  };

  const authorData = linkedAuthor?.document?.data;

  // collect chapter titles from body
  const chapterTitleData = sliceData.filter((slice) => slice.slice_type === 'chapter_title');
  const chapterTitles = [];
  chapterTitleData.forEach((item) => {
    const { primary } = item;
    const { chapter_title: chapterTitle } = primary;
    const { text: titleText } = chapterTitle;
    chapterTitles.push(titleText);
  });

  const defaultAuthor = {
    bio: {
      html: '<p>Maddie is the marketing coordinator at Driva knowledgeable in all things car finance related. When she’s not writing informing, helpful articles you can find her at the beach or spending time with her family. </p>',
      text: 'Maddie is the marketing coordinator at Driva knowledgeable in all things car finance related. When she’s not writing informing, helpful articles you can find her at the beach or spending time with her family. ',
    },
    image: {
      url: 'https://images.prismic.io/driva/7aea1a39-a6ff-4cf2-b4b9-b2c00bce052d_MB+Headshot.jpg?auto=compress%2Cformat&rect=0%2C0%2C600%2C600&w=500&h=500',
    },
    linkedin: {
      url: 'https://www.linkedin.com/in/madeline-barclay-abba2a133/',
    },
    name: {
      html: '<p>Maddie Barclay</p>',
      text: 'Maddie Barclay',
    },
  };

  const authorToUse = authorData?.name?.text ? authorData : defaultAuthor;
  const blogUrl = `${process.env.GATSBY_SITE_URL}/blog${generatePath(uid)}`;

  const navLink = { url: navCtaLink?.url, text: navCtaText?.text };
  const escapeDoubleQuotes = (str) => str.replace(/\\([\s\S])|(")/g, '\\$1$2');

  const hasReviewSchemaFields =
    productType &&
    name?.text &&
    brand?.text &&
    itemDescription?.text &&
    image?.url &&
    reviewDescription?.text &&
    rating;

  const reviewSchema = `
    <script type="application/ld+json">
        {
            "@context": "http://schema.org",
            "@type": "${productType}",
            "name": "${name?.text}",
            "brand": {
                "@type": "Brand",
                "name": "${brand?.text}"
            },
            "description": "${escapeDoubleQuotes(itemDescription?.text)}",
            "image": {
                "@type": "ImageObject",
                "url": "${image?.url}"
            },
            "review": [
                {
                    "@type": "Review",
                    "author": {
                        "@type": "Person",
                        "name": "${authorToUse?.name?.text}",
                        "url": "${authorToUse?.linkedin.url}"
                    },
                    "datePublished": "${publishDate}",
                    "description": "${escapeDoubleQuotes(reviewDescription?.text)}",
                    "name": "${name?.text} Review",
                    "url": "${blogUrl}",
                    "reviewRating": {
                        "@type": "Rating",
                        "bestRating": "5",
                        "ratingValue": "${rating}",
                        "worstRating": "1"
                    }
                }
            ]
        }
    </script>
  `;

  const newsSchema = `
    <script type="application/ld+json">
        {
            "@context": "http://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${blogUrl}"
            },
            "headline": "${title?.text}",
            "image": [
                "${heroImage?.url}"
            ],
            "datePublished": "${publishDate}",
            "dateModified": "${lastModifiedDate}",
            "author": {
                "@type": "Person",
                "name": "${authorToUse?.name?.text}",
                "url": "${authorToUse?.linkedin.url}"
            },
            "publisher": {
                "@type": "Organization",
                "name": "Driva",
                "logo": {
                    "@type": "ImageObject",
                    "url": "${process.env.GATSBY_SITE_URL}/driva-icon.png"
                }
            },
            "description": "${escapeDoubleQuotes(metaDesc?.text)}"
        }
    </script>
  `;

  return (
    <>
      <Layout location={location} seo={seo} uid={uid} navLink={navLink}>
        <div className={styles.blogPost}>
          <BlogHeader title={title} author={author} timestamp={timestamp} category={category} dataRaw={dataRaw} />
          <ScrollBar />
          {heroImage && <Image image={heroImage} className={styles.heroImage} />}
          {/* SLICE CONTENT */}
          <div className={`${type === 'short-form' ? styles.postContainerShortForm : styles.postContainerLongForm}`}>
            <div className={styles.sliceContent}>
              {sliceData.map((slice) => (
                <Slice key={slice.id} data={slice} isBlog />
              ))}
              <AuthorCard author={authorToUse} />
            </div>
            {/* SIDEBAR */}
            <div className={styles.sidebarContainer}>
              {type === 'short-form' ? (
                <BlogPostSidebarShortForm
                  posts={relatedArticlesNodes}
                  className={styles.sidebar}
                  cta={ctaData}
                  location={location}
                />
              ) : (
                <TableOfContents
                  chapterTitles={chapterTitles}
                  className={styles.sidebar}
                  cta={ctaData}
                  location={location}
                />
              )}
            </div>
          </div>
          {/* RECENT POSTS */}
          <CategoryThreeCardCol
            categoryPosts={recentPostsNodes}
            className={styles.recentPosts}
            title="Recent Posts"
            noViewAll
          />
          <BlogFooterCta className={styles.footerCta} />
        </div>
        {hasReviewSchemaFields && <div dangerouslySetInnerHTML={{ __html: reviewSchema }} />}
        <div dangerouslySetInnerHTML={{ __html: newsSchema }} />
      </Layout>
      <PopupCta />
    </>
  );
};

export default withPrismicPreview(BlogPost);

export const blogPostQuery = graphql`
  query BlogPostBySlug($blogUid: String!, $categoryUid: String!) {
    prismicBlogPost(uid: { eq: $blogUid }) {
      _previewable
      id
      uid
      prismicId
      dataRaw
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        timestamp(formatString: "DD MMMM YYYY")
        title {
          html
          text
        }
        type
        author {
          html
          text
        }
        linked_author {
          document {
            ... on PrismicAuthor {
              id
              data {
                bio {
                  html
                  text
                }
                image {
                  url
                }
                linkedin {
                  url
                }
                name {
                  text
                  html
                }
              }
            }
          }
        }
        excerpt {
          html
          text
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
            }
          }
        }
        category {
          uid
          document {
            ... on PrismicCategory {
              data {
                category_name {
                  text
                }
              }
            }
          }
        }
        body {
          ...FaqAccordionBlogPostFragment
          ...WysiwygBlogPostFragment
          ...BreakoutBoxBlogPostFragment
          ...ChapterTitleBlogPostFragment
          ...IframeEmbedBlogPostFragment
          ...LenderInfoBlogPostFragment
          ...BlogPostDotPointTips
          ...ImageBlospostFragment
          ...ProductCarouselSliderCta
          ...BlogCalculatorFragment
          ...InTextCta
          ...TwoColumnTableBlogPostFragment
          ...ThreeColumnTableBlogPostFragment
          ...FourColumnTableBlogPostFragment
          ...FiveColumnTableBlogPostFragment
        }
        # Review Schema
        product_type
        name {
          text
          html
        }
        brand {
          text
          html
        }
        image {
          url
        }
        item_description {
          text
          html
        }
        review_description {
          text
          html
        }
        rating
      }
    }
    relatedArticles: allPrismicBlogPost(
      filter: { uid: { ne: $blogUid }, data: { category: { uid: { eq: $categoryUid } } } }
      limit: 2
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
              }
            }
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                id
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    recentPosts: allPrismicBlogPost(limit: 3, sort: { fields: data___timestamp, order: DESC }) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.3333333333333335)
              }
            }
          }
          category {
            uid
            document {
              ... on PrismicCategory {
                id
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    settings: prismicSettings {
      data {
        blog_popular_posts_breakout_background_colour
        blog_popular_posts_breakout_content {
          text
        }
        blog_popular_posts_breakout_cta_link {
          url
        }
        blog_popular_posts_breakout_cta_text {
          text
        }
        blog_popular_posts_breakout_title {
          text
        }
        blog_navigation_cta_text {
          text
        }
        blog_navigation_cta_link {
          url
        }
      }
    }
  }
`;
