import { Section } from 'components';
import React, { useEffect, useRef } from 'react';
import { removeRelAttribute } from '../../helpers/removeRelAttribute';
import useSessionStorage from '../../hooks/useSessionStorage';
import * as styles from './styles.module.scss';

const Wysiwyg = (props) => {
  const { data, isPage, isBlog } = props;
  const { primary } = data;
  const { wysiwyg, container_styles: containerStyles } = primary;

  const wysiwygHtml = wysiwyg?.html;
  const pageVariant = isPage ? styles.pageVariant : '';

  const htmlRef = useRef(null);

  const sessionStorage = useSessionStorage('session');

  useEffect(() => {
    const utms = sessionStorage.getItem('queriesObj');
    const parsedUtms = utms ? JSON.parse(utms) : null;
    const hasUtms = parsedUtms && Object.keys(parsedUtms).length > 0;

    if (htmlRef?.current && hasUtms) {
      const links = htmlRef?.current?.getElementsByTagName('a');

      [...links].forEach((link) => {
        const { href } = link;
        if (href) {
          try {
            const url = new URL(href);
            const containsSiteUrl = href.indexOf(process.env.GATSBY_SITE_URL) !== -1;
            if (containsSiteUrl) {
              Object.entries(parsedUtms).forEach(([key, value]) => {
                url.searchParams.set(key, value);
              });
              link.setAttribute('href', url);
            }
          } catch (error) {
            console.error('Error fetching WYSIWYG links', error);
          }
        }
      });
    }
  }, [htmlRef, sessionStorage]);

  return (
    <Section
      className={`${pageVariant} ${styles.wysiwyg}`}
      containerClassName={containerStyles || styles.wysiwygContainer}
      noContainer={isBlog}
      sliceName="Wysiwyg"
    >
      {wysiwygHtml && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: removeRelAttribute(wysiwyg.html) }}
          ref={htmlRef}
        />
      )}
    </Section>
  );
};

export default Wysiwyg;
