import React from 'react';
import * as styles from './styles.module.scss';

const AnchorPoint = (props) => {
  const { data } = props;
  const { primary } = data;
  const { anchor_title: title } = primary;

  const titleText = title?.text;

  const anchorId = titleText ? titleText.replace(/\s+/g, '-').toLowerCase() : '';

  return <div className={styles.anchorPoint} id={anchorId} />;
};

export default AnchorPoint;
