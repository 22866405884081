// *********CONFIG************************************
export const loanTermOptions = [3, 4, 5, 7];

export const balloonPaymentOptions = [
  { '0%': 0 },
  { '10%': 10 },
  { '20%': 20 },
  { '30%': 30 },
  { '40%': 40 },
  { '50%': 50 },
  { '60%': 60 },
  { '70%': 70 },
  { '80%': 80 },
];
export const creditScoreOptions = [
  { title: 'Poor', value: 'poor' },
  { title: 'Average', value: 'good' },
  { title: 'Great', value: 'great' },
  { title: 'Excellent', value: 'excellent' },
];
export const repaymentFrequencyOptions = ['Weekly', 'Fortnightly', 'Monthly'];

export const creditScoreTooltipItems = [
  { title: 'Poor:', content: 'Some past credit issues' },
  { title: 'Average:', content: 'Occasional missed payment, but no defaults or other issues' },
  { title: 'Great:', content: 'Limited credit history, but so far so good!' },
  { title: 'Excellent:', content: 'Perfect history, past credit and never missed a repayment' },
];

export const comparisonRateTooltip = [
  {
    content:
      'The comparison rate applies only to the example or examples given. Different amounts and terms will result in different comparison rates. Costs such as redraw fees or early repayment fees, and cost savings such as fee waivers, are not included in the comparison rate but may influence the cost of the loan. The comparison rate is based on a $30,000 loan over 5 years.',
  },
];
export const balloonPaymentTooltip = [
  {
    content:
      'Also known as a balloon or residual payment, this is a lump sum payment payable to the lender at the end of your loan term. While you may end up paying more interest with a balloon, your monthly repayments will likely be lower',
  },
];
