import React, { useState, useRef, useEffect, useFocus } from 'react';
import { CheckBox } from 'components';

import * as styles from './styles.module.scss';

const TypedInput = ({
  inputType,
  inputState,
  setInputState,
  fieldTitle,
  placeholder,
  checkBoxField,
  setCheckBoxActive,
  checkBoxActive,
  hasCheckBox = false,
  selectOptions,
  isCurrency = false,
  setFormActive,
  formActive,
  tooltip,
  isSmallerToolTip,
  isTopTooltip,
  vehicleIsLessThanDeposit,
}) => {
  const [renderTypedInput, setRenderTypedInput] = useState(false);
  const [selectActive, setSelectActive] = useState(true);

  const formattedCurrency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(inputState);

  const numberInput = useRef();

  useEffect(() => {
    if (renderTypedInput && inputState) {
      numberInput?.current?.focus();
    }
  }, [renderTypedInput]);

  const handleSelectChange = () => {
    if (!selectActive) {
      setSelectActive(true);
    }

    if (!formActive) {
      setFormActive(true);
    }
  };

  const onFocusInput = () => {
    setRenderTypedInput(true);
    if (!formActive) {
      setFormActive(true);
    }
  };

  const isCreditScore = placeholder === 'Enter your credit score';
  const isVehiclePrice = fieldTitle === 'Vehicle price';

  return (
    <>
      <div className={styles.checkBoxRow}>
        <span className={styles.label}>{fieldTitle}</span>
        {hasCheckBox && (
          <CheckBox
            checkBoxActive={checkBoxActive}
            setCheckBoxActive={setCheckBoxActive}
            checkBoxField={checkBoxField}
            tooltip={tooltip}
            isSmallerToolTip={isSmallerToolTip}
            isTopTooltip={isTopTooltip}
          />
        )}
      </div>
      {inputType === 'text' && (
        <div>
          {renderTypedInput && isCurrency && (
            <div className={styles.currencyContainer}>
              <input
                ref={numberInput}
                type="number"
                onFocus={() => onFocusInput()}
                onBlur={() => setRenderTypedInput(false)}
                className={styles.input}
                onKeyPress={(event) => {
                  if (!/[0-9]*\.?[0-9]*/.test(event?.key)) {
                    event.preventDefault();
                  }
                }}
                value={inputState}
                onChange={(e) => setInputState(e?.target?.value)}
                placeholder={placeholder}
              />
            </div>
          )}

          {!renderTypedInput && isCurrency && (
            <>
              <input
                className={styles.input}
                value={formattedCurrency}
                placeholder={placeholder}
                onFocus={() => onFocusInput()}
                readOnly
              />
              {isVehiclePrice && vehicleIsLessThanDeposit && (
                <p className={styles.errorMessage}>Vehicle price should be greater than the deposit amount</p>
              )}
            </>
          )}

          {!isCurrency && (
            <div>
              <input
                type="number"
                className={styles.input}
                onKeyPress={(event) => {
                  if (!/[0-9]*\.?[0-9]*/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                value={inputState}
                onChange={(e) => setInputState(e.target.value)}
                placeholder={placeholder}
              />
              {isCreditScore && inputState > 1200 && (
                <p style={{ color: '#d9534f', position: 'absolute' }}>Credit score must be between 0 and 1,200</p>
              )}
            </div>
          )}
        </div>
      )}
      {inputType === 'select' && (
        <select
          onChange={(e) => setInputState(e.target.value)}
          onFocus={() => handleSelectChange()}
          className={selectActive ? styles.selectActive : styles.select}
          value={inputState}
        >
          {selectOptions.map((option, index) => (
            <option value={Object.values(option)} key={`${index + 1}`}>
              {Object.keys(option)}
            </option>
          ))}
        </select>
      )}
    </>
  );
};

export default TypedInput;
