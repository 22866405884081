import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Slice, BlogHeader, BlogFooterCta, CategoryThreeCardCol, LatestPosts, Section } from 'components';
import * as styles from './category.module.scss';

const Category = ({ location, data }) => {
  const { prismicCategory, categoryBlogPosts = {}, prismicSettings } = data;
  const { nodes: posts = [] } = categoryBlogPosts;
  const { uid, data: categoryData } = prismicCategory;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    category_name: categoryName,
    category_page_header_title: categoryPageTitle,
    category_page_header_subtitle: categoryPageSubtitle,
  } = categoryData;
  const {
    data: { blog_navigation_cta_text: navCtaText, blog_navigation_cta_link: navCtaLink },
  } = prismicSettings;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
  };

  const recentPosts = posts.slice(0, 3);
  const remainingPosts = posts.slice(3, posts?.length);

  const navLink = { url: navCtaLink?.url, text: navCtaText?.text };

  return (
    <Layout location={location} seo={seo} uid={uid} navLink={navLink}>
      <BlogHeader
        title={categoryPageTitle}
        subtitle={categoryPageSubtitle}
        landingPage
        categoryPage
        category={categoryName}
      />
      <Section className={styles.topContainer}>
        {/* <div className={styles.postCount}>
          Showing {postsToShow + 3} of {posts?.length} posts
        </div> */}
        <LatestPosts latestPosts={recentPosts} isCategory />
      </Section>
      <CategoryThreeCardCol categoryPosts={remainingPosts} noViewAll isPaginated />{' '}
      <div className={styles.sliceContent}>
        {sliceData.map((slice) => (
          <Slice key={slice.id} data={slice} isBlog />
        ))}
      </div>
      {/* {remainingPosts.length > postsToShow && !reachedEnd && (
        <div className="post-grid-load-more">
          <button type="button" onClick={handleLoadMore} className="post-grid-button button">
            Load More
          </button>
        </div>
      )} */}
      <BlogFooterCta />
    </Layout>
  );
};

export default withPrismicPreview(Category);

export const categoryQuery = graphql`
  query CategoryBySlug($uid: String!) {
    prismicCategory(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      data {
        meta_description {
          text
        }
        meta_title {
          text
        }
        open_graph_image {
          url
        }
        category_name {
          text
          html
        }
        category_page_header_title {
          text
          html
        }
        category_page_header_subtitle {
          text
          html
        }
        body {
          ...WysiwygCategoryFragment
          ...ThreeColumnCardCategoryFragment
        }
      }
    }
    categoryBlogPosts: allPrismicBlogPost(
      filter: { data: { category: { uid: { eq: $uid } } } }
      sort: { order: DESC, fields: data___timestamp }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          excerpt {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.3333333333333335, placeholder: NONE)
              }
            }
          }
          category {
            document {
              ... on PrismicCategory {
                uid
                data {
                  category_name {
                    text
                  }
                }
              }
            }
          }
          timestamp(formatString: "DD MM YYYY")
        }
      }
    }
    prismicSettings {
      data {
        blog_navigation_cta_text {
          text
        }
        blog_navigation_cta_link {
          url
        }
      }
    }
    prismicBlogPost {
      data {
        sub_category {
          document {
            ... on PrismicCarBuyingGuideSubcategories {
              id
              data {
                subcategory_name {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
