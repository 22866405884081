import React, { useState, Fragment, useEffect } from 'react';
import { Section, Carousel, Image, Modal, Link, OnVisible } from 'components';
import { ReactComponent as YellowArrows } from 'images/yellow-arrows.svg';
import * as styles from './styles.module.scss';

const CustomerStories = (props) => {
  const { data } = props;
  const { primary, items } = data;

  const { image_grid: imageGrid, slider_image_right: sliderImageRight, background, title } = primary;

  const gridData = [];

  items.forEach((item) => {
    gridData.push({
      imageData: item?.image,
      imageGridCtaTextData: item?.image_grid_cta_text,
    });
  });

  const [showModal, setShowModal] = useState(false);
  const [showModalIndex, setShowModalIndex] = useState(null);
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true
  const [slick, setSlick] = useState(null);
  const slickObj = slick?.current;

  // custom button handlers
  const handleLeftClick = () => {
    slickObj.slickPrev();
  };
  const handleRightClick = () => {
    slickObj.slickNext();
  };

  const openModal = (event, index) => {
    event.preventDefault();
    setShowModalIndex(index);
    setShowModal(true);
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModalIndex(null);
    setShowModal(false);
  };

  const lockBody = () => {
    // lock body while modal open
    document.body.classList.add('modal-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when modal closed, unlock body
    document.body.classList.remove('modal-open');
    setBodyLock(false);
  };
  // use effect when showmodal updated
  useEffect(() => {
    if (showModal && !bodyLocked) lockBody();
    if (!showModal && bodyLocked) unlockBody();
  }, [showModal]);

  return (
    <>
      <Section
        className={`${styles.customerStories} ${background === 'Mint' ? styles.mintBg : ''}`}
        containerClassName={styles.container}
      >
        {title?.text && <div dangerouslySetInnerHTML={{ __html: title.html }} className={styles.title} />}
        <OnVisible visibleClassName={styles.visibleArrows}>
          <YellowArrows className={styles.yellowArrows} />
        </OnVisible>
        <Carousel
          settings={{
            dots: false,
            arrows: false,
            cssEase: 'ease',
          }}
          sliderRef={setSlick}
        >
          {items.map((item, index) => {
            const {
              title: itemTitle,
              tagline,
              content,
              modal_content: modalContent,
              slider_cta_text: sliderCtaTextData,
              image,
              role,
              name,
              company,
            } = item;

            // content checks
            const itemTitleHtml = itemTitle?.html;
            const taglineHtml = tagline?.html;
            const contentHtml = content?.html;
            const modalContentHtml = modalContent?.html;
            const sldierCtaText = sliderCtaTextData?.text; // remove this field
            const hasImage = image?.localFile?.childImageSharp?.gatsbyImageData || image.url;

            return (
              <Fragment key={itemTitleHtml}>
                <div className={`${styles.imageTextContainer} ${sliderImageRight && styles.sliderImageRight}`}>
                  <div className={styles.leftColumn}>
                    <div className={styles.authorContainer}>
                      {hasImage && <Image image={image} className={styles.image} />}
                      {name?.text && <div className={styles.name} dangerouslySetInnerHTML={{ __html: name.html }} />}
                      {role?.text && <div className={styles.role} dangerouslySetInnerHTML={{ __html: role.html }} />}
                      {company?.text && (
                        <div className={styles.company} dangerouslySetInnerHTML={{ __html: company.html }} />
                      )}
                    </div>
                    <div className={styles.buttonsContainer}>
                      <button
                        type="button"
                        onClick={handleLeftClick}
                        aria-label="left-button"
                        className={styles.button}
                      />
                      <button
                        type="button"
                        onClick={handleRightClick}
                        aria-label="right-button"
                        className={styles.button}
                      />
                    </div>
                  </div>
                  <div className={styles.textContainer}>
                    {itemTitleHtml && (
                      <div className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: itemTitleHtml }} />
                    )}
                    {contentHtml && (
                      <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />
                    )}
                  </div>
                </div>
                {showModalIndex === index ? (
                  <Modal active={showModal} closeModal={closeModal} className={styles.drivaModal}>
                    {hasImage && (
                      <div className={styles.modalImageContainer}>
                        <Image image={image} className={styles.modalImage} />
                      </div>
                    )}
                    {modalContentHtml && (
                      <div className={styles.modalContent} dangerouslySetInnerHTML={{ __html: modalContentHtml }} />
                    )}
                    <Link className="button" to="https://dash.driva.com.au/welcome">
                      Get rates
                    </Link>
                  </Modal>
                ) : null}
              </Fragment>
            );
          })}
        </Carousel>
      </Section>
      {/* TESTIMONIAL GRID */}
      {imageGrid && <ImageGrid data={gridData} openModal={openModal} />}
    </>
  );
};

const ImageGrid = ({ data, openModal }) => (
  <Section className={styles.imageGridSection} containerClassName={styles.imageGrid}>
    {data.map((gridItem, gridIndex) => {
      const { imageData, imageGridCtaTextData } = gridItem;
      const hasGridImage = imageData?.localFile?.childImageSharp?.gatsbyImageData || imageData.url;
      const imageGridCtaText = imageGridCtaTextData?.text;
      return (
        <div className={styles.gridImageContainer} key={imageData?.url}>
          {hasGridImage && (
            <a href="#open" onClick={(e) => openModal(e, gridIndex)}>
              <Image image={imageData} className={styles.gridImage} />
            </a>
          )}
          <a
            href="#open"
            onClick={(e) => openModal(e, gridIndex)}
            className="button black"
            style={{ color: '#ffffff' }}
          >
            {imageGridCtaText}
          </a>
        </div>
      );
    })}
  </Section>
);

export default CustomerStories;
