import React, { useState, useEffect } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Section, Image, Link } from 'components';
import { ReactComponent as Chevron } from '../../images/down-chevron.svg';
import * as styles from './styles.module.scss';

const ThreeColsReviews = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  const [showAll, setshowAll] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [width, setWidth] = useState(null);

  const titleHtml = title?.html;

  const onShowAll = () => {
    setshowAll(!showAll);
    const node = document.getElementById('scrollTo');
    setReviews(items.slice(0, items.length));

    showAll &&
      scrollIntoView(node, {
        scrollMode: 'if-needed',
        block: 'start',
        duration: 1000,
      });
  };

  useEffect(() => {
    let initialWidth;
    width ? (initialWidth = width) : (initialWidth = window.innerWidth);
    const isMobile = initialWidth <= 660;

    !showAll && setReviews(items.slice(0, 6));
    isMobile && !showAll && setReviews(items.slice(0, 2));
  }, [showAll, width]);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    };
  }, []);

  const imgStyles = {
    height: '2.2em',
    width: '100%',
    position: 'absolute',
    top: 0,
    opacity: 1,
    flex: 1,
  };

  const ReviewCard = ({ item }) => {
    const { content, cta_link: ctaLink, cta_text: ctaText, logo } = item;
    return (
      <div className={styles.card}>
        {logo?.url && <Image image={logo} className={styles.logo} objectFit="contain" imgStyle={imgStyles} />}
        {content?.html && (
          <div className={styles.contentWrapper}>
            <div dangerouslySetInnerHTML={{ __html: content.html }} className={`body-font ${styles.cardContent}`} />
          </div>
        )}
        {ctaLink?.url && ctaText?.text && (
          <Link to={ctaLink.url} className={`button ${styles.readReview}`}>
            Read Review
          </Link>
        )}
      </div>
    );
  };

  return (
    <Section sliceName="ThreeColsReviews" className={`three-cols-reviews ${styles.ThreeColsReviews}`}>
      <div className={styles.scrollRef} id="scrollTo" />
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}

      <div className={styles.cardsContainer}>
        {reviews.map((item) => (
          <ReviewCard item={item} key={item?.cta_text?.text} />
        ))}
      </div>
      <div className={styles.viewAll}>
        <div className={styles.showAllRow} onClick={onShowAll} role="button" tabIndex={0}>
          <p className={styles.viewAllCTA}>
            {!showAll ? `View all reviews +(${items.length - reviews.length} more)` : `Show less`}
          </p>
          <button className={showAll ? styles.chevronButtonUp : styles.chevronButtonDown} type="button">
            <Chevron className={styles.chevron} />
          </button>
        </div>
      </div>
    </Section>
  );
};

export default ThreeColsReviews;
