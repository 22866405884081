import React from 'react';
import { Section, Image, Link, OnVisible } from 'components';
import { ReactComponent as YellowArrows } from '../../images/yellow-arrows.svg';
import * as styles from './styles.module.scss';

const Cta = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    title,
    align_image_left: alignImageLeft,
    background_colour: bgColour,
    is_mail_to: isMailto,
    person_type: personType,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const hasCta = ctaText?.text && ctaLink?.url;
  const hasImage = image?.url;
  // dynamic classes
  const formattedBg = bgColour ? bgColour.toLowerCase() : '';
  const bgClasses = {
    white: styles.white,
    cream: styles.cream,
    green: styles.green,
    purple: styles.purple,
  };
  const alignmentClass = alignImageLeft ? styles.alignImageLeft : '';
  const mailToVariant = isMailto ? styles.centered : '';
  const personVariant = personType ? styles.personVersion : '';

  const classNames = `${styles.cta} ${alignmentClass} ${bgClasses[formattedBg]} ${mailToVariant} ${personVariant}`;

  return (
    <Section sliceName="Cta" className={`cta ${classNames}`}>
      <div className={styles.textContainer}>
        {/* TITLE */}
        {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {/* CTA LINK */}
        {!isMailto && hasCta && (
          <Link className={`button primary ${styles.ctaButton || ''}`} to={ctaLink.url}>
            {ctaText.text}
          </Link>
        )}
        {isMailto && hasCta && (
          <a className="button" href={ctaLink.url}>
            {ctaText.text}
          </a>
        )}
      </div>
      {/* IMAGE */}
      {personType && (
        <OnVisible visibleClassName={styles.visible}>
          <YellowArrows className={styles.yellowArrows} />
        </OnVisible>
      )}
      {hasImage && (
        <div className={styles.imageContainer}>
          <Image className={styles.image} image={image} objectFit="contain" />
        </div>
      )}
    </Section>
  );
};

export default Cta;
