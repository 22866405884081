import React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import { Link } from 'components';
import { SiteProvider } from '../../../context/site';
import Page from '../../../templates/page.jsx';
import BlogPost from '../../../templates/blog_post.jsx';
import Category from '../../../templates/category.jsx';
import linkResolver from '../../../../config/linkResolver';

const PrismicPreviewProviderWrapper = (props) => {
  const { element } = props;
  return (
    <SiteProvider>
      <PrismicPreviewProvider
        internalLinkComponent={({ href, ...linkProps }) => <Link to={href} {...linkProps} />}
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver,
            componentResolver: componentResolverFromMap({
              page: Page,
              blog_post: BlogPost,
              category: Category,
            }),
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </SiteProvider>
  );
};

export default PrismicPreviewProviderWrapper;
