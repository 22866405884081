import React from 'react';
// import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Link } from 'components';
import * as styles from './styles.module.scss';

// function to scroll to top
const scrollToNext = (event) => {
  event.preventDefault();
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  // const pageTop = document.getElementById('5109685c-fcee-5fb5-aba9-c6826b48aeac');
  // if (pageTop) smoothScrollIntoView(pageTop, { behavior: 'smooth', block: 'start' });
};

const ScrollToTop = () => (
  <div className={styles.scrollToTop}>
    <Link to="#top" onClick={scrollToNext}>
      Back to top
    </Link>
  </div>
);

export default ScrollToTop;
