// extracted by mini-css-extract-plugin
export var active = "blog_post-module--active--10441";
export var blogPost = "blog_post-module--blogPost--2cd68";
export var heroImage = "blog_post-module--heroImage--c6d0e";
export var hidden = "blog_post-module--hidden--dd1a2";
export var postContainerLongForm = "blog_post-module--postContainerLongForm--8e8b1";
export var postContainerShortForm = "blog_post-module--postContainerShortForm--cb818";
export var recentPosts = "blog_post-module--recentPosts--f392b";
export var sidebar = "blog_post-module--sidebar--bc871";
export var sidebarContainer = "blog_post-module--sidebarContainer--ffeaa";
export var sliceContent = "blog_post-module--sliceContent--ca289";