import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Image, Button } from 'components';
import * as styles from './styles.module.scss';

const BlogFooterCta = ({ className }) => {
  const {
    blogFooterCta: { data },
  } = useStaticQuery(blogFooterCtaQuery);
  const {
    blog_footer_cta_icon: ctaIcon,
    blog_footer_cta_link: ctaLink,
    blog_footer_cta_text: ctaText,
    blog_footer_cta_title: title,
  } = data;

  return (
    <Section containerClassName={styles.ctaSectionContainer} className={`${styles.BlogFooterCta}${className || ''}`}>
      {title?.text && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title?.html }} />}
      {ctaText?.text && (
        <Button to={ctaLink.url} theme="secondary">
          {ctaText?.text}
        </Button>
      )}
      {ctaIcon && (
        <div className={styles.iconContainer}>
          <Image className={styles.icon} image={ctaIcon} />
        </div>
      )}
    </Section>
  );
};

export default BlogFooterCta;

export const blogFooterCtaQuery = graphql`
  query BlogFooterCtaSettings {
    blogFooterCta: prismicSettings {
      data {
        blog_footer_cta_title {
          html
          text
        }
        blog_footer_cta_text {
          html
          text
        }
        blog_footer_cta_link {
          url
        }
        blog_footer_cta_icon {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;
