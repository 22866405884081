import React from 'react';
import { Link } from 'components';
// @ts-ignore
import * as styles from './styles.module.scss';

type Theme = 'white' | 'whiteOutline' | 'yellow' | 'secondary';

interface TeslaButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string;
  theme: Theme;
  children: React.ReactNode;
  className?: string;
}

const TeslaButton = ({ to, theme, children, className, ...rest }: TeslaButtonProps) => {
  const themeStyles = styles[theme] || '';
  if (to) {
    return (
      <Link to={to} className={`${styles.button} ${themeStyles} ${className || ''}`} {...rest}>
        {children}
      </Link>
    );
  }
  return (
    <button className={`${styles.button} ${themeStyles} ${className || ''}`} type="button" {...rest}>
      {children}
    </button>
  );
};

export default TeslaButton;
