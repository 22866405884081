import React from 'react';
import { Link, Section } from 'components';
import * as styles from './styles.module.scss';

const CategoryButtons = ({ data }) => {
  const { buttons, buttonsTitle: title, buttonsSubtitle: subtitle } = data;

  return (
    <Section className={styles.categoryButtonsSection}>
      {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title?.html }} />}
      {subtitle && <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle?.html }} />}
      <div className={styles.buttonsContainer}>
        {buttons &&
          buttons.map((category, index) => {
            const categoryText = category?.blog_category?.document?.data?.category_name?.text;
            const categoryLink = category?.blog_category?.uid;
            return (
              <Link
                key={categoryText}
                to={`/blog/${categoryLink}/`}
                className={styles.button}
                style={{ transitionDelay: `${index * 1}` }}
              >
                {categoryText}
              </Link>
            );
          })}
      </div>
    </Section>
  );
};

export default CategoryButtons;
