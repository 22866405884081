// extracted by mini-css-extract-plugin
export var button = "styles-module--button--16770";
export var docTitle = "styles-module--docTitle--dc8f7";
export var document = "styles-module--document--97e44";
export var documentContainer = "styles-module--documentContainer--037d8";
export var documentsList = "styles-module--documentsList--c2bac";
export var grid = "styles-module--grid--1d502";
export var icon = "styles-module--icon--601f8";
export var image = "styles-module--image--7636f";
export var leftCol = "styles-module--leftCol--7eebc";
export var tagline = "styles-module--tagline--a30d3";
export var taglineContainer = "styles-module--taglineContainer--8a428";
export var title = "styles-module--title--85d55";