// Adjust the regex to include both .tsx and .jsx
const context = require.context('./', true, /\.\/[^/]+\/[^/]+\/index\.(jsx|tsx)$/);

context.keys().forEach((filePath) => {
  // Remove the './' and './svg' from the object key
  const componentName = filePath.replace(/^.+\/([^/]+)\/index\.(jsx|tsx)/, '$1');

  if (process.env.NODE_ENV === 'development') {
    console.log('%cExporting component: ', 'color:green', componentName);
  }

  // Dynamically export the component
  module.exports[componentName] = context(filePath).default;
});
