import React from 'react';
import { Section, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Stats = (props) => {
  const { data } = props;
  const { items } = data;

  return (
    <Section sliceName="Stats" className={styles.section} containerClassName={styles.container}>
      <div className={styles.grid}>
        {items.map((item, index) => {
          const { content, property, value } = item;
          return (
            <OnVisible
              className={styles.statCard}
              style={{ transitionDelay: `${index * 0.2}s` }}
              visibleClassName={styles.visibleCard}
            >
              {value?.text && <div dangerouslySetInnerHTML={{ __html: value?.html }} className={styles.value} />}
              {property?.text && (
                <div dangerouslySetInnerHTML={{ __html: property?.html }} className={styles.property} />
              )}
              {content?.text && <div dangerouslySetInnerHTML={{ __html: content?.html }} className={styles.content} />}
            </OnVisible>
          );
        })}
      </div>
    </Section>
  );
};

export default Stats;
