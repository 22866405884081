import React from 'react';
import { Section } from 'components';
import { ReactComponent as Warning } from '../../images/yellow-shebang.svg';
import { ReactComponent as Tick } from '../../images/green-tick.svg';
import { ReactComponent as Cross } from '../../images/red-cross.svg';

import * as styles from './styles.module.scss';

const DotPointTips = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, full_width: fullWidth } = primary;

  const noBullets = items.length < 1 ? styles.greenVariant : '';
  const isFullWidth = fullWidth ? styles.fullWidth : '';

  const icons = {
    Tick: <Tick className={styles.pointIcon} />,
    Cross: <Cross className={styles.pointIcon} />,
    Warning: <Warning className={styles.pointIcon} />,
  };

  return (
    <Section sliceName="DotPointTips" className={`dot-point-tips ${styles.dotPointTips} ${isFullWidth}`}>
      <div className={`${styles.dotPoints} ${noBullets}`}>
        {content?.html && <div className={styles.title} dangerouslySetInnerHTML={{ __html: content?.html }} />}
        {items.map((point) => {
          const { dot_point_icon: icon, dot_point_text: text } = point;
          return (
            <div className={styles.dotPoint} key={text.text}>
              {icons[icon]}
              {text?.text && <div className={styles.pointText} dangerouslySetInnerHTML={{ __html: text?.html }} />}
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default DotPointTips;
