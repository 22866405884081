import React from 'react';
import { Button } from 'components';
import * as styles from './styles.module.scss';

const PopularPostsCta = ({ ctaData }) => {
  const {
    popularPostsCtaTitle: title,
    popularPostsCtaContent: content,
    popularPostsCtaText: ctaText,
    popularPostsCtaLink: ctaLink,
    popularPostsCtaBgColour: bgColour,
  } = ctaData;

  return (
    <div className={`${styles.ctaContainer} ${bgColour && styles[bgColour.toLowerCase()]}`}>
      {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title?.html }} />}
      {content && <div className={styles.content} dangerouslySetInnerHTML={{ __html: content?.html }} />}
      {ctaText && (
        <Button to={ctaLink?.url} theme="secondary">
          {ctaText?.text}
        </Button>
      )}
    </div>
  );
};

export default PopularPostsCta;
