import React from 'react';
import { PostCard } from 'components';
import * as styles from './styles.module.scss';

const LatestPosts = ({ latestPosts, isCategory }) => {
  const categoryClass = isCategory ? styles.categoryVariant : '';
  return (
    <div className={`${styles.latestPostsContainer} ${categoryClass}`}>
      <span className={styles.title}>Latest Posts</span>
      <div className={styles.postGrid}>
        {latestPosts?.map((post, index) => (
          <PostCard
            key={`card_${index}`}
            post={post}
            readMore
            showExcerpt={!(isCategory && index !== 0)}
            className={`${styles.cardContainer} ${index === 0 ? styles.heroCardContainer : ''}`}
            latestPosts
          />
        ))}
      </div>
    </div>
  );
};

export default LatestPosts;
