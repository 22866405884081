import React from 'react';
import { Section, Image, TeslaText as Text, TeslaButton as Button } from 'components';

import * as styles from './styles.module.scss';

const TwoColumnWithImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    background_image: backgroundImage,
    title,
    description,
    cta_text: ctaText,
    cta_link: ctaLink,
    image_alignment: rightAlignedImage,
  } = primary;

  const hasCta = ctaText?.text && ctaLink?.url;
  const alignmentClass = rightAlignedImage ? styles.rightImage : styles.leftImage;

  return (
    <Section sliceName="TwoColumnWithImage" noContainer className={`${styles.section} ${alignmentClass}`}>
      <div className={styles.contentContainer}>
        <div className={styles.imageContainer}>
          <Image image={backgroundImage} className={styles.image} />
        </div>
        <div className={styles.content}>
          <Text {...title} />
          <Text {...description} />
          {hasCta && (
            <div className={styles.buttonContainer}>
              <Button to={ctaLink.url} theme="whiteOutline">
                {ctaText.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default TwoColumnWithImage;
