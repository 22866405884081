import React from 'react';
import { Image, Link } from 'components';
import * as styles from './styles.module.scss';

const PostCard = (props) => {
  const { post, readMore, className, showExcerpt, latestPosts, isThreeCols, sideBar } = props;
  const { data, uid } = post;
  const { title, hero_image: image, excerpt, timestamp, category: blogCategory } = data;

  const categoryUid = blogCategory?.document?.uid;
  const category = blogCategory?.document?.data?.category_name?.text;
  // rendering variants
  const latestPostsVariant = latestPosts ? styles.latest : '';
  const threeColsVariant = isThreeCols ? styles.threeCols : '';
  const sideBarVariant = sideBar ? styles.sideBar : '';
  const classNames = `${className || ''} ${latestPostsVariant} ${threeColsVariant} ${sideBarVariant}`;

  return (
    <div className={`${styles.cardContainer} ${classNames} `}>
      {image && (
        <Link to={`/blog/${uid}`}>
          <Image image={image} className={styles.image} />
        </Link>
      )}
      {category && (
        <Link to={`/blog/${categoryUid}`} className={styles.category}>
          {category}
        </Link>
      )}
      {title?.text && (
        <Link to={`/blog/${uid}`}>
          <span className={styles.title}>{title.text}</span>
        </Link>
      )}
      {showExcerpt && excerpt && <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: excerpt?.html }} />}
      {readMore && (
        <Link className={styles.link} to={`/blog/${uid}`}>
          Read More
        </Link>
      )}
    </div>
  );
};

export default PostCard;
