import React, { useState, useEffect } from 'react';
import { Image, Section } from 'components';
import { ReactComponent as Driva } from 'images/driva-logo-2.svg';
import { ReactComponent as Cross } from 'images/black_cross.svg';
import useSessionStorage from '../../../hooks/useSessionStorage';
import * as styles from './styles.module.scss';

const ReferralBanner = ({ referrer, className }) => {
  const [closeBanner, setCloseBanner] = useState(false);
  const sessionStorage = useSessionStorage('session');

  const handleClose = (event) => {
    event.preventDefault();
    setCloseBanner(true);
  };

  useEffect(() => {
    if (closeBanner) {
      sessionStorage.setItem('dismissed', 'true');
    }
  }, [closeBanner, sessionStorage]);

  useEffect(() => {
    const isDismissed = sessionStorage.getItem('dismissed');
    if (isDismissed === 'true') {
      setCloseBanner(true);
    }
  }, [sessionStorage]);

  return (
    <Section className={`${className || ''} ${styles.banner} ${closeBanner ? styles.bannerClosed : ''}`}>
      <div className={styles.infoContainer}>
        <div className={styles.drivaLogo}>
          <Driva />
        </div>
        <span className={styles.middText}>in partnership with</span>
        <span className={styles.middTextMobile}>x</span>
        <Image image={referrer.logo} className={styles.partnerLogo} />
      </div>
      <button type="button" onClick={handleClose} className={`button ${styles.button}`}>
        <span>Dismiss</span>
        <Cross className={styles.cross} />
      </button>
    </Section>
  );
};

export default ReferralBanner;
