import React from 'react';
import { Section, Image, OnVisible } from 'components';
import { ReactComponent as YellowLines } from '../../images/yellow-lines.svg';
import * as styles from './styles.module.scss';

const TextBlock = (props) => {
  const { data, location } = props;
  const { primary } = data;
  const { content, image, title } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasImage = image?.url;
  const oneCol = !title?.text ? styles.oneColVersion : '';
  const productVariant = location?.pathname?.includes('product') ? styles.productVariant : '';

  return (
    <Section sliceName="TextBlock" className={`text-block ${styles.textBlock} ${productVariant} ${oneCol}`}>
      <div className={styles.textColumns}>
        {title?.text && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        <div>
          {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
          {hasImage && (
            <OnVisible className={styles.imageContainer} visibleClassName={styles.visible}>
              <YellowLines className={styles.yellowLines} />
              <Image className={styles.image} image={image} />
            </OnVisible>
          )}
        </div>
      </div>
    </Section>
  );
};

export default TextBlock;
