import React from 'react';
import { Link } from 'components';
import * as styles from './styles.module.scss';

const today = new Date();
const year = today.getFullYear();

const FooterLegal = ({ licenseNo, abn }) => (
  <div className={styles.footerLegalContainer}>
    <div>
      <div className={styles.termsContainer}>
        <Link to="/legal">Privacy Policy | </Link>
        <Link to="/legal">Terms &amp; Conditions</Link>
      </div>
      <span>Copyright &copy; {year} Driva Pty Ltd. All rights reserved.</span>
    </div>
    <div>
      <span>ABN {abn}</span>
      <span>Australian Credit Licence No. {licenseNo}</span>
    </div>
  </div>
);

export default FooterLegal;
