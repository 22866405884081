import React from 'react';
import { Skeleton, RateItem } from 'components';
import { ReactComponent as InfoIcon } from '../../../images/info-icon-green.svg';
import { comparisonRateTooltip } from '../../Common/Calculator/calculatorConfig';

import * as styles from './styles.module.scss';
import 'react-rangeslider/lib/index.css';

const StickyRepayment = ({
  repaymentFrequency,
  returnData,
  isBadResponse,
  animate,
  inView,
  inViewSecondary,
  decimalHelper,
}) => (
  <>
    <div className={inView || inViewSecondary ? styles.finance : styles.financeAnimate}>
      <p className={styles.tagline}>{repaymentFrequency} repayment</p>

      <div className={styles.row}>
        <div className={styles.columnOne}>
          {returnData && !returnData?.error && (
            <h3 className={animate ? styles.animateMainText : styles.mainText} id="repaymentMobile">
              {returnData[repaymentFrequency?.toLowerCase()]?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
            </h3>
          )}
          {!returnData && !returnData?.error && <h3 className={styles.mainText}>77</h3>}
          {returnData?.error && (
            <h3 className={styles.mainTextBadResponse} style={{ opacity: 0.3 }}>
              {returnData?.errorMessage}
            </h3>
          )}
          {animate && !returnData?.error && <Skeleton />}
        </div>
        <div className={styles.comparisonContainer}>
          <RateItem
            type="comparison"
            className={styles.comparisonRate}
            returnData={returnData}
            isBadResponse={isBadResponse}
            decimalHelper={decimalHelper}
            animate={animate}
          />
        </div>
      </div>
    </div>
  </>
);

export default StickyRepayment;
