import React from 'react';
import * as styles from './styles.module.scss';

const Video = ({ id, src, type = 'video/mp4', autoPlay, className }) => (
  <div className={`${styles.container} ${className || ''}`}>
    <video className={styles.video} preload="true" autoPlay={autoPlay} playsInline muted loop id={id}>
      <source src={src} type={type} />
      Sorry, your browser doesn&#39;t support embedded videos.
    </video>
  </div>
);

export default Video;
