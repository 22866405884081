import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const FourColumnTable = ({ data }) => {
  return (
    <Section className={styles.fourColTable} sliceName="FourColumnTable" noContainer>
      <div className={styles.fourColTableDiv}>
        {data.items.map((item) => {
          return (
            <div key={item.idx} className={styles.fourColTableContainer}>
              <div className={styles.colOne}>{item.columnone.text}</div>
              <div className={styles.colTwo}>{item.columntwo.text}</div>
              <div className={styles.colThree}>{item.columnthree.text}</div>
              <div className={styles.colFour}>{item.columnfour.text}</div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default FourColumnTable;
