// extracted by mini-css-extract-plugin
export var content = "styles-module--content--361b5";
export var contentContainer = "styles-module--contentContainer--ec2e1";
export var cta = "styles-module--cta--06676";
export var extraText = "styles-module--extraText--dd0ec";
export var icon = "styles-module--icon--486cb";
export var partnersGrid = "styles-module--partnersGrid--697bb";
export var purpleArrows = "styles-module--purpleArrows--8312e";
export var text = "styles-module--text--4ef94";
export var title = "styles-module--title--c51bb";
export var tooltip = "styles-module--tooltip--bb539";
export var visible = "styles-module--visible--80405";