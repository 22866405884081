// extracted by mini-css-extract-plugin
export var animateMainText = "styles-module--animateMainText--0b868";
export var buttonSticky = "styles-module--buttonSticky--b16ae";
export var columnOne = "styles-module--columnOne--d4cdf";
export var comparisonRate = "styles-module--comparisonRate--1e7f7";
export var fadeIn = "styles-module--fadeIn--f1ac6";
export var fadeInTranslate = "styles-module--fadeInTranslate--33493";
export var fadeOut = "styles-module--fadeOut--ce3f1";
export var fadeOutTranslate = "styles-module--fadeOutTranslate--382c0";
export var finance = "styles-module--finance--33494";
export var financeAnimate = "styles-module--financeAnimate--4d7ee";
export var heading = "styles-module--heading--19b50";
export var mainText = "styles-module--mainText--869d1";
export var mainTextBadResponse = "styles-module--mainTextBadResponse--8c33a";
export var outline = "styles-module--outline--d660a";
export var row = "styles-module--row--30a26";
export var tagline = "styles-module--tagline--536ba";