import React from 'react';
import { Section, Image, TeslaText as Text } from 'components';
import { ReactComponent as Phone } from './phone.svg';

import * as styles from './styles.module.scss';

const ContactSection = (props) => {
  const { data } = props;
  const { primary } = data;
  const { background_image: backgroundImage, title, subtitle, cta_text: ctaText, cta_link: ctaLink } = primary;

  const hasBackgroundImage = backgroundImage?.url;
  const hasCta = ctaText?.text && ctaLink?.url;

  return (
    <Section sliceName="ContactSection" className={styles.section}>
      {hasBackgroundImage && <Image image={backgroundImage} className={styles.image} />}
      <div className={styles.content}>
        <Text {...title} />
        <Text text={subtitle?.text} as="h5" />
        {hasCta && (
          <div className={styles.phoneNumber}>
            <Phone className={styles.icon} />
            <a href={ctaLink.url} className={styles.number}>
              {ctaText.text}
            </a>
          </div>
        )}
      </div>
    </Section>
  );
};

export default ContactSection;
