import React from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const ImageSlice = (props) => {
  const { data } = props;
  const { primary } = data;
  const { image } = primary;

  return (
    <Section sliceName="ImageSlice" className={styles.section} noContainer>
      <Image image={image} className={styles.image} />
    </Section>
  );
};

export default ImageSlice;
