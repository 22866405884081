import React from 'react';
import { Section, Link, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const BreakoutBox = (props) => {
  const { data } = props;
  const { primary } = data;
  const {
    background_colour: bgColour,
    breakout_content: content,
    breakout_cta_link: ctaLink,
    breakout_cta_text: ctaText,
    breakout_title: title,
  } = primary;

  const backgroundColours = {
    White: styles.white,
    Pink: styles.pink,
    Green: styles.green,
  };

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaText?.text && ctaLink?.url;

  return (
    <Section
      sliceName="BreakoutBox"
      containerClassName={styles.breakoutContainer}
      className={`breakout-box ${styles.breakoutBox} ${backgroundColours[bgColour]}`}
    >
      <OnVisible className={styles.textContainer} visibleClassName={styles.visible}>
        {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {content?.text && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        {hasCta && (
          <Link className="button" to={ctaLink?.url}>
            {ctaText.text}
          </Link>
        )}
      </OnVisible>
    </Section>
  );
};

export default BreakoutBox;
