import React, { useState, useEffect } from 'react';
import { Section, OnVisible, Link, ScrollSequencer } from 'components';
import * as styles from './styles.module.scss';

const LenderBubbles = (props) => {
  const { data } = props;
  const {
    primary: { content, cta_link: ctaLink, cta_text: ctaText, title, video, video_placeholder: videoPlaceholder },
  } = data;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  const [visible, setVisible] = useState(false);

  return (
    <Section sliceName="LenderBubbles" className={styles.section} containerClassName={styles.container}>
      <OnVisible className={styles.visibilitySensor} onChange={setVisible} threshold={0} />
      {titleHtml && <OnVisible dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {/* <div className={styles.sequencerContainer} ref={sequencerContainerRef}> */}
      <div className={styles.sequencerContainer}>
        <ScrollSequencer
          filePrefix="/lender-long/driva-animation"
          fileExtension=".jpg"
          padAmount={3}
          width={750}
          height={575}
          totalFrames={116}
          className={styles.sequencer}
          canvasContainerClassName={styles.canvasContainer}
          canvasClassName={styles.canvas}
          visible={visible}
        />
      </div>
      <OnVisible className={styles.bottomText} visibleClassName={styles.visibleBottomText}>
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
        {hasCta && (
          <Link to={ctaLink.url} className={`button ${styles.button}`}>
            {ctaText?.text}
          </Link>
        )}
      </OnVisible>
    </Section>
  );
};

export default LenderBubbles;
