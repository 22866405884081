import React, { useState } from 'react';
import { Section, TeslaText as Text } from 'components';
import * as styles from './styles.module.scss';

const FaqAccordion = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title } = primary;

  const hasTitle = title?.text;

  const [activeIndex, setActiveIndex] = useState([0]);

  const handleOnClick = (event, index) => {
    event.preventDefault();
    setActiveIndex((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((prevIndex) => prevIndex !== index);
      }
      return [...prevIndexes, index];
    });
  };

  const escapeDoubleQuotes = (str) => str.replace(/\\([\s\S])|(")/g, '\\$1$2');

  const schema = `
  <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        ${items
    .map(
      (item) => `
          {
            "@type": "Question",
            "name": "${escapeDoubleQuotes(item.question?.text)}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${escapeDoubleQuotes(item.answer?.text)}"
            }
          }`
    )
    .join(',')}
      ]
    }
  </script>
  `;

  return (
    <Section sliceName="FaqAccordion" className={styles.faqSection}>
      <div className={styles.contentContainer}>
        {hasTitle && <Text {...title} />}
        <div className={styles.faqContent}>
          {items.map((item, index) => {
            const { question, answer } = item;
            const questionText = question?.text;
            const answerHtml = answer?.html;
            const isActive = activeIndex.includes(index);

            return (
              <div key={questionText} className={styles.accordionItem} aria-hidden="true">
                <button
                  type="button"
                  className={styles.accordionHeader}
                  onClick={(event) => handleOnClick(event, index)}
                >
                  <div className={styles.accordionQuestion}>{questionText}</div>
                  <div className={styles.accordionToggleIconContainer}>
                    <div className={`${styles.accordionToggleIcon} ${isActive ? styles.active : styles.inactive}`} />
                  </div>
                </button>
                {answerHtml && (
                  <div
                    className={`${styles.accordionAnswer} ${isActive ? styles.active : styles.inactive}`}
                    dangerouslySetInnerHTML={{ __html: answerHtml }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: schema }} />
    </Section>
  );
};

export default FaqAccordion;
