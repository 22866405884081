import React from 'react';
// @ts-ignore
import * as styles from './styles.module.scss';

interface TeslaTextProps {
  html?: string;
  text?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'ol' | 'ul' | 'li' | 'blockquote' | 'div';
  children: React.ReactNode;
  className?: string;
}

const TeslaText = ({ html, text, children, as, className, ...rest }: TeslaTextProps) => {
  if (html) {
    return <div className={`${styles.text} ${className}`} dangerouslySetInnerHTML={{ __html: html }} {...rest} />;
  }
  if (text || children) {
    const Element = as || 'span';
    return (
      <div className={`${styles.text} ${className}`} {...rest}>
        <Element>{text || children}</Element>
      </div>
    );
  }
  return null;
};

export default TeslaText;
