import React from 'react';
import { Section, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TwoColsText = (props) => {
  const { data } = props;
  const { items: columns } = data;

  return (
    <Section containerClassName={styles.twoCols}>
      {columns.map((column) => {
        const { column_item_content: content, title, dark_background: darkBg } = column;
        const titleHtml = title?.html;
        const contentHtml = content?.html;
        const colDark = darkBg ? styles.colDark : '';

        return (
          <OnVisible key={title.text} className={`${colDark} ${styles.col}`} visibleClassName={styles.visible}>
            {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
            {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.colContent} />}
          </OnVisible>
        );
      })}
    </Section>
  );
};

export default TwoColsText;
