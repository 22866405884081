import React from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Section, OnVisible, Image, Link } from 'components';
import { ReactComponent as CGULogo } from '../../images/cgu.svg';
import * as styles from './styles.module.scss';

const StackedComparison = (props) => {
  const { data } = props;
  const { items: rows, primary } = data;
  const { content, title, variant } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;

  const usePoweredByCGU = variant === 'Powered by CGU';

  const handleCGUClick = (event) => {
    event.preventDefault();
    const anchorElement = document.getElementById(`documents-slice`);
    if (anchorElement) {
      scrollIntoView(anchorElement, { behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Section
      sliceName="IconColumns"
      containerClassName={styles.container}
      className={`stacked-comparison ${styles.stackedComparison}`}
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      <div className={styles.rows}>
        {rows.map((row, index) => {
          const {
            cta_link: ctaLink,
            cta_text: ctaText,
            column_one_title: columnOneTitle,
            column_one_content: columnOneContent,
            column_two_title: columnTwoTitle,
            column_two_content: columnTwoContent,
            column_three_title: columnThreeTitle,
            column_three_content: columnThreeContent,
            column_four_title: columnFourTitle,
            column_four_content: columnFourContent,
            product_title: productTitle,
            product_icon: productIcon,
          } = row;
          const productTitleHtml = productTitle?.html;
          const columnOneTitleHtml = columnOneTitle?.html;
          const columnOneContentHtml = columnOneContent?.html;
          const columnTwoTitleHtml = columnTwoTitle?.html;
          const columnTwoContentHtml = columnTwoContent?.html;
          const columnThreeTitleHtml = columnThreeTitle?.html;
          const columnThreeContentHtml = columnThreeContent?.html;
          const columnFourTitleHtml = columnFourTitle?.html;
          const columnFourContentHtml = columnFourContent?.html;
          const hasCta = ctaText?.text && ctaLink?.url;
          return (
            <OnVisible
              key={productTitleHtml.text}
              className={styles.rowItem}
              visibleClassName={styles.visible}
              style={{ transitionDelay: `${index * 0.2}s` }}
            >
              <div className={styles.rowItemTop}>
                <div className={styles.rowItemProduct}>
                  {productIcon?.url && (
                    <div className={styles.iconContainer}>
                      <Image className={styles.icon} image={productIcon} />
                    </div>
                  )}
                  {productTitle && (
                    <div dangerouslySetInnerHTML={{ __html: productTitleHtml }} className={styles.colTitle} />
                  )}
                </div>
                {hasCta && (
                  <Link className={`button ${styles.desktopCta}`} to={ctaLink.url}>
                    {ctaText?.text}
                  </Link>
                )}
              </div>
              <div className={styles.rowItemBody}>
                <div className={styles.column}>
                  {columnOneTitleHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnOneTitleHtml }} className={styles.colTitle} />
                  )}
                  {columnOneContentHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnOneContentHtml }} className={styles.colContent} />
                  )}
                </div>
                <div className={styles.column}>
                  {columnTwoTitleHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnTwoTitleHtml }} className={styles.colTitle} />
                  )}
                  {columnTwoContentHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnTwoContentHtml }} className={styles.colContent} />
                  )}
                </div>
                <div className={styles.column}>
                  {columnThreeTitleHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnThreeTitleHtml }} className={styles.colTitle} />
                  )}
                  {columnThreeContentHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnThreeContentHtml }} className={styles.colContent} />
                  )}
                </div>
                <div className={styles.column}>
                  {columnFourTitleHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnFourTitleHtml }} className={styles.colTitle} />
                  )}
                  {columnFourContentHtml && (
                    <div dangerouslySetInnerHTML={{ __html: columnFourContentHtml }} className={styles.colContent} />
                  )}
                </div>
                {hasCta && (
                  <Link className={`button ${styles.tabletCta}`} to={ctaLink.url}>
                    {ctaText?.text}
                  </Link>
                )}
              </div>
            </OnVisible>
          );
        })}
      </div>
      {usePoweredByCGU && (
        <div className={styles.poweredByContainer}>
          <div className={styles.poweredBy}>
            <strong>Powered by</strong>
            <CGULogo />
          </div>
          <a href="#documents-list" onClick={handleCGUClick}>
            Read the PDS for the full breakdown
          </a>
        </div>
      )}
    </Section>
  );
};

export default StackedComparison;
