import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'components';
import { ReactComponent as WhiteCross } from '../../../images/white-cross.svg';
import * as styles from './styles.module.scss';
import useLocalStorage from '../../../hooks/useLocalStorage';

const PopupCta = () => {
  const query = graphql`
    query {
      ...BlogPopupCtaFragment
    }
  `;

  const staticData = useStaticQuery(query);

  const { prismicSettings } = staticData;
  const { data } = prismicSettings;

  const localStorage = useLocalStorage();

  const {
    blog_popup_cta_enabled: ctaEnabled,
    blog_popup_cta_link: ctaLink,
    blog_popup_cta_text: ctaText,
    blog_popup_cta_title: ctaTitle,
  } = data;

  const [showPopupCta, setShowPopupCta] = useState(false);
  const [disablePopupCta, setDisablePopupCta] = useState();

  const handleScroll = () => {
    const docHeight = document.body.scrollHeight;
    const popupPoint = docHeight ? docHeight / 2 : 2800;
    const { pageYOffset: scrollPosition } = window;
    if (!showPopupCta && scrollPosition > popupPoint) {
      setShowPopupCta(true);
    }
    if (showPopupCta && scrollPosition <= popupPoint) {
      setShowPopupCta(false);
    }
  };

  useEffect(() => {
    // ADD EVENT LISTENER FOR SCROLL
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      // REMOVE EVENT LISTENER FOR SCROLL
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showPopupCta]);

  useEffect(() => {
    const ctaDisabled = localStorage.getItem('disableCta');
    setDisablePopupCta(ctaDisabled);
  }, [localStorage]);

  const handleClose = () => {
    localStorage.setItem('disableCta', true);
    setDisablePopupCta(true);
  };

  if (disablePopupCta || !ctaEnabled) return null;

  const firstLine = ctaTitle?.text?.slice(0, 17);
  const secondLine = ctaTitle?.text?.slice(17);

  return (
    <div className={`${styles.container} ${showPopupCta ? styles.active : styles.hidden}`}>
      <span className={styles.text}>{firstLine}</span>
      <span className={styles.text}>{secondLine}</span>
      <div className={styles.crossContainer}>
        <WhiteCross className={styles.whiteCross} onClick={() => handleClose()} />
      </div>
      <Link to={ctaLink?.url} className={`button ${styles.button}`}>
        {ctaText?.text}
      </Link>
    </div>
  );
};

export default PopupCta;
