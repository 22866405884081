import React, { useState, useEffect, useRef } from 'react';
import * as styles from './styles.module.scss';

const CountrySwitcher = () => {
  const [showSelector, setShowSelector] = useState(false);

  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSelector(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  function toggleSelectDisplay() {
    const state = showSelector;
    setShowSelector(!state);
  }

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  return (
    <div className={styles.selectorWrapper} ref={wrapperRef}>
      {showSelector && (
        <div className={styles.dropdownSelector}>
          <ul>
            <li>
              <a href="https://driva.co.nz">🇳🇿 New Zealand</a>
            </li>
            <li onClick={toggleSelectDisplay}>🇦🇺 Australia &#10003;</li>
          </ul>
        </div>
      )}
      <p className={styles.currentCountry} onClick={toggleSelectDisplay}>
        🇦🇺 Australia
      </p>
    </div>
  );
};

export default CountrySwitcher;
