// extracted by mini-css-extract-plugin
export var ctaButton = "styles-module--ctaButton--eecc8";
export var ctaSection = "styles-module--ctaSection--c8e28";
export var feeText = "styles-module--feeText--01138";
export var feesContainer = "styles-module--feesContainer--d5cd0";
export var gridSection = "styles-module--gridSection--bc219";
export var loanCard = "styles-module--loanCard--75a4e";
export var logo = "styles-module--logo--4793e";
export var numbersGrid = "styles-module--numbersGrid--82aef";
export var price = "styles-module--price--abec2";
export var priceCircles = "styles-module--priceCircles--b13ff";
export var priceContainer = "styles-module--priceContainer--3f439";
export var priceText = "styles-module--priceText--1c830";
export var property = "styles-module--property--cd717";
export var term = "styles-module--term--bb2f9";
export var tick = "styles-module--tick--1500b";
export var topDetails = "styles-module--topDetails--dc6e4";
export var topSection = "styles-module--topSection--79041";
export var value = "styles-module--value--dce99";