import { useState, useEffect } from 'react';

const useLocalStorage = () => {
  const [ls, setLs] = useState({
    getItem: () => {},
    setItem: () => {},
  });

  useEffect(() => {
    try {
      localStorage.getItem('');
      setLs({
        getItem: (value) => localStorage.getItem(value),
        setItem: (key, value) => localStorage.setItem(key, value),
      });
    } catch (error) {
      console.error('Access to localStorage blocked');
    }
  }, []);

  return ls;
};

export default useLocalStorage;
