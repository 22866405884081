// extracted by mini-css-extract-plugin
export var balloonBtn = "styles-module--balloonBtn--5854e";
export var buttonIcon = "styles-module--buttonIcon--293bb";
export var calculator = "styles-module--calculator--3e518";
export var checkBoxRow = "styles-module--checkBoxRow--f935b";
export var columnOne = "styles-module--columnOne--fcfd4";
export var columnTwo = "styles-module--columnTwo--f7110";
export var container = "styles-module--container--9fabc";
export var field = "styles-module--field--24b33";
export var inputLabel = "styles-module--inputLabel--0a072";
export var label = "styles-module--label--11120";
export var labelMobile = "styles-module--labelMobile--47b14";
export var stickyWrapper = "styles-module--stickyWrapper--93afa";