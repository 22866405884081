// extracted by mini-css-extract-plugin
export var accordionAnswer = "styles-module--accordionAnswer--9a382";
export var accordionHeader = "styles-module--accordionHeader--6d03c";
export var accordionItem = "styles-module--accordionItem--2f060";
export var accordionQuestion = "styles-module--accordionQuestion--7e432";
export var accordionToggleIcon = "styles-module--accordionToggleIcon--06e79";
export var accordionToggleIconContainer = "styles-module--accordionToggleIconContainer--ef396";
export var active = "styles-module--active--b47ec";
export var contentContainer = "styles-module--contentContainer--35f06";
export var faqContent = "styles-module--faqContent--5d134";
export var faqSection = "styles-module--faqSection--f92dc";
export var faqTitle = "styles-module--faqTitle--4e422";
export var inactive = "styles-module--inactive--243b1";