import React from 'react';
import { Section, Image, TeslaText as Text, TeslaButton as Button } from 'components';
import { ReactComponent as Check } from '../../images/tesla/check.svg';
import { ReactComponent as Cross } from '../../images/tesla/cross.svg';

import * as styles from './styles.module.scss';

const ComparisonTableWithCta = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    background_image: backgroundImage,
    title,
    description,
    cta_text: ctaText,
    cta_link: ctaLink,
    image_alignment: rightAlignedImage,
  } = primary;

  const hasCta = ctaText?.text && ctaLink?.url;
  const alignmentClass = rightAlignedImage ? styles.rightImage : styles.leftImage;

  return (
    <Section sliceName="ComparisonTableWithCta" noContainer className={`${styles.section} ${alignmentClass}`}>
      <div className={styles.imageContainer}>
        <Image image={backgroundImage} className={styles.image} />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentTop}>
            <Text {...title} />
            <Text {...description} />
          </div>
          <div className={styles.comparisonTable}>
            <div className={styles.tableHeaders}>
              <p className={`${styles.tableHeader} ${styles.tesla}`}>LeaseMyTesla</p>
              <p className={`${styles.tableHeader} ${styles.others}`}>Others</p>
            </div>
            {items.map((item) => (
              <div key={item.feature_title.text} className={styles.tableRow}>
                <div className={styles.textContainer}>
                  <div className={styles.featureTitle}>{item.feature_title.text}</div>
                  <div className={styles.featureDescription}>{item.feature_description.text}</div>
                </div>
                <div className={styles.icons}>
                  <div className={styles.icon}>
                    <ComparisonIcon included={item.lease_my_tesla_included} />
                  </div>
                  <div className={styles.icon}>
                    <ComparisonIcon included={item.others_included} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {hasCta && (
            <Button to={ctaLink.url} theme="yellow">
              {ctaText.text}
            </Button>
          )}
        </div>
      </div>
    </Section>
  );
};

const ComparisonIcon = ({ included }) => {
  if (included) {
    return <Check />;
  }
  return <Cross />;
};

export default ComparisonTableWithCta;
