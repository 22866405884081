// extracted by mini-css-extract-plugin
export var animateMainTextPercentage = "styles-module--animateMainTextPercentage--2b7c5";
export var comparisonItem = "styles-module--comparisonItem--c83fa";
export var fadeIn = "styles-module--fadeIn--721c9";
export var fadeOut = "styles-module--fadeOut--27f9f";
export var infoIcon = "styles-module--infoIcon--9d33e";
export var mainTextPercentage = "styles-module--mainTextPercentage--fb3c2";
export var mainTextPercentageInvalid = "styles-module--mainTextPercentageInvalid--03b10";
export var mainTextPercentageInvalidAnimate = "styles-module--mainTextPercentageInvalidAnimate--ffbcd";
export var rateItem = "styles-module--rateItem--89f1c";
export var skeletonContainer = "styles-module--skeletonContainer--78e4d";
export var strong = "styles-module--strong--8d3b4";
export var tagline = "styles-module--tagline--6d61d";
export var titleRow = "styles-module--titleRow--547da";
export var tooltip = "styles-module--tooltip--0739f";
export var tooltipTop = "styles-module--tooltipTop--74513";
export var top = "styles-module--top--a8198";