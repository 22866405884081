import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { LoadingSpinner, OnVisible } from 'components';
import * as styles from './styles.module.scss';

export const TrustPilotReviews = () => {
  const [showWidget, setShowWidget] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    if (showWidget && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [showWidget]);

  if (!showWidget) {
    return (
      <OnVisible className={styles.container} onChange={setShowWidget}>
        <LoadingSpinner />
      </OnVisible>
    );
  }

  return (
    <div className={styles.container} onChange={setShowWidget}>
      <Helmet>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      </Helmet>
      <div
        ref={ref}
        className={`trustpilot-widget ${styles.widget}`}
        data-locale="en-US"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="5e4b32c580da5a0001aed9a1"
        data-style-height="400px"
        data-style-width="100%"
        data-theme="light"
        data-stars="2,3,4,5"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/driva.com.au"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Trustpilot"
        />
      </div>
    </div>
  );
};
export default TrustPilotReviews;
