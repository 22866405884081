import React from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const ComparisonTable = (props) => {
  const { data } = props;
  const { primary, items } = data;

  // data
  const {
    icon,
    title,
    content,
    column_two_title: colTwoTitle,
    column_three_title: colThreeTitle,
    column_one_title: colOneTitle,
    column_four_title: colFourTitle,
    is_responsive: isResponsive,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  // establish number of columns for conditional styling
  const headerData = [];
  // if col one empty, push empty html for dummy column
  if (colOneTitle?.html) {
    headerData.push(colOneTitle);
  } else {
    headerData.push({ html: <span />, text: '' });
  }
  if (colTwoTitle?.html) headerData.push(colTwoTitle);
  if (colThreeTitle?.html) headerData.push(colThreeTitle);
  if (colFourTitle?.html) headerData.push(colFourTitle);

  // if isResponsive boolean is true, pass responsiveVariant class to Section
  const responsiveVariant = isResponsive ? styles.responsiveVariant : '';

  return (
    <Section
      containerClassName={styles.container}
      sliceName="ComparisonTable"
      className={`comparison-table ${responsiveVariant} ${styles.comparisonTable}`}
    >
      {icon?.url && (
        <div className={styles.iconWrapper}>
          <Image className={styles.icon} image={icon} />
        </div>
      )}
      {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      {contentHtml && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      <div className={styles.tableView}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            {/* TABLE HEADER */}
            <thead>
              <tr className={`${styles.headerRow} ${styles.row}`}>
                {headerData.map((heading) => {
                  const { text } = heading;
                  // Get length of string in cell
                  const headingTextLength = heading.text.length;
                  // Dynamically sets min-width of heading cell based on length of content
                  const cellLarge = headingTextLength > 20 ? styles.cellLarge : '';
                  const cellLarger = headingTextLength > 50 ? styles.cellLarger : '';
                  return (
                    <th className={`${cellLarge} ${cellLarger} ${styles.headerCell}`} key={text}>
                      {text && <span className={styles.cellText}>{text}</span>}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {/* TABLE DATA  */}
            <tbody>
              {items.map((row, rowIndex) => {
                const {
                  feature,
                  row_data_2: rd2,
                  row_data_3: rd3,
                  row_data_4: rd4,
                  row_data_5: rd5,
                  highlight_row: highlight,
                } = row;

                const rowRaw = [feature, rd2, rd3, rd4, rd5];
                const rowData = rowRaw.filter((rData) => rData?.text);
                const isHightlighted = highlight ? styles.highlightedRow : '';

                if (!rowData[0]) return null;

                return (
                  <tr className={`${styles.row} ${isHightlighted}`} key={feature?.text || rowIndex}>
                    {rowData.map((cell, columnIndex) => {
                      const { html: cellHtml, text } = cell;
                      // Get length of string in cell
                      const rowDataTextLength = cell.text.length;
                      // Dynamically sets min-width of cell based on length of content
                      const cellLarge = rowDataTextLength > 20 ? styles.cellLarge : '';
                      const cellLarger = rowDataTextLength > 50 ? styles.cellLarger : '';
                      const dataLabels = {
                        0: '',
                        1: headerData[1]?.text,
                        2: headerData[2]?.text,
                        3: headerData[3]?.text,
                      };
                      return (
                        <td
                          className={`${styles.cell} ${cellLarge} ${cellLarger}`}
                          key={`${text} + ${columnIndex}`}
                          data-label={dataLabels[columnIndex]}
                        >
                          {cellHtml && (
                            <div dangerouslySetInnerHTML={{ __html: cellHtml }} className={styles.cellText} />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Section>
  );
};

export default ComparisonTable;
