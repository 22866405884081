import React from 'react';
import { Image, Link } from 'components';
import * as styles from './styles.module.scss';

const ProductCard = ({ data }) => {
  const { cta_link: ctaLink, cta_text: ctaText, product_description: description, product_title: title, image } = data;
  const classNames = '';
  return (
    <div className={`${styles.cardContainer} ${classNames} `}>
      <Image image={image} />
      <div className={styles.cardContent}>
        <span className={styles.title}>{title?.text}</span>
        <span className={styles.description}>{description?.text}</span>
        <Link to={ctaLink.url} theme="secondary" className={`button ${styles.button}`}>
          {ctaText?.text}
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
