import React from 'react';
import { Footer, TeslaHeader, SEO, NoJs } from 'components';
import '../../../sass/global/styles.scss';
import './styles.scss';

const TeslaLayout = (props) => {
  const { children, uid, seo, location, navLink } = props;

  return (
    <>
      <NoJs />
      <TeslaHeader location={location} navLink={navLink} />
      <main className={uid}>
        <SEO {...seo} location={location} />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default TeslaLayout;
