import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './styles.scss';

const Image = (props) => {
  const { image, alt, className = '', imgStyle, loading, ...otherProps } = props;
  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;

  const imageData = image?.gatsbyImageData || getImage(image?.localFile);

  if (imageData) {
    return (
      <GatsbyImage
        className={`gatsby-image ${className}`}
        image={imageData}
        alt={image.alt || alt || ''}
        copyright={image.copyright || alt || ''}
        imgStyle={imgStyle}
        loading={loading}
        {...otherProps}
      />
    );
  }

  if (image.url) {
    return (
      <div className={`gatsby-image ${className}`}>
        <img
          src={image.url}
          alt={image.alt || alt || ''}
          copyright={image.copyright || alt || ''}
          style={imgStyle}
          {...image?.dimensions}
        />
      </div>
    );
  }

  return <div className={`gatsby-image placeholder ${className}`} />;
};

export default Image;
