import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Carousel, ProductCard } from 'components';
import * as styles from './styles.module.scss';

const ProductCarouselSliderCta = (props) => {
  const { data } = props;
  const { primary } = data;
  const items = data?.items;
  const title = primary?.title1?.text || 'Get your lowest rate from 30+ lenders';

  const query = graphql`
    query {
      ...ProductCarouselSliderCtaGlobal
    }
  `;

  const staticData = useStaticQuery(query);
  const settingsProds = staticData?.prismicSettings?.data?.product;
  const product = items.length > 2 ? items : settingsProds;

  if (!product) {
    return null;
  }

  return (
    <Section
      sliceName="ProductCarouselCTA"
      containerClassName={styles.container}
      className={`productCarouselCta ${styles.carouselContainer}`}
    >
      <span className={styles.title}>{title}</span>
      <div className={styles.carousel}>
        <Carousel
          settings={{
            dots: true,
            arrows: true,
            cssEase: 'ease',
            centerMode: false,
            centerPadding: 0,
            variableWidth: true,
            responsive: [
              {
                breakpoint: 660,
                settings: {
                  arrows: false,
                },
              },
            ],
          }}
        >
          {product &&
            product.map((card) => {
              return <ProductCard data={card} />;
            })}
        </Carousel>
      </div>
    </Section>
  );
};

export default ProductCarouselSliderCta;
