import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const TwoColumnTable = ({ data }) => {
  return (
    <Section className={styles.twoColumnTable} sliceName="TwoColumnTable" noContainer>
      <div className={styles.twoColumnTableDiv}>
        {data.items.map((item) => {
          return (
            <div key={item.idx} className={styles.twoColumnTableContainer}>
              <div className={styles.colOne}>{item.columnone.text}</div>
              <div className={styles.colTwo}>{item.columntwo.text}</div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default TwoColumnTable;
