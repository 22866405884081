const generatePath = require('./generatePath');

module.exports = (doc) => {
  const { uid } = doc;
  if (uid === 'home') {
    return `/`;
  }
  // Example of link resolver for other post type
  // --------------------------------------------
  if (doc.type === 'blog_post' || doc.type === 'category') {
    return `/blog/${uid}/`;
  }
  return generatePath(doc.uid);
};
