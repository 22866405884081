import { useState, useEffect } from 'react';

const useSessionStorage = () => {
  const [ss, setSs] = useState({
    getItem: () => {},
    setItem: () => {},
  });

  useEffect(() => {
    try {
      sessionStorage.getItem('');
      setSs({
        getItem: (value) => sessionStorage.getItem(value),
        setItem: (key, value) => sessionStorage.setItem(key, value),
      });
    } catch (error) {
      console.error('Access to sessionStorage blocked');
    }
  }, []);

  return ss;
};

export default useSessionStorage;
