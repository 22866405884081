import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Image, Link, Section } from 'components';
import * as styles from './styles.module.scss';

import generatePath from '../../../helpers/generatePath';

const CityGridByState = ({ currentState }) => {
  // get data
  const citiesQuery = graphql`
    query {
      ...CitiesByState
    }
  `;
  const { allGoogleCarLoanCityPagesSheet } = useStaticQuery(citiesQuery);
  const { edges } = allGoogleCarLoanCityPagesSheet;

  const cityData = [];
  // format data
  edges.forEach(({ node }) => {
    const { city, headerImage, id, slug, state } = node;
    if (state === currentState) {
      cityData.push({ name: city, img: { url: headerImage }, id, slug, state });
    }
  });

  if (cityData.length === 0) {
    return null;
  }

  return (
    <Section className={`city-grid ${styles.cityGrid}`}>
      <span className={styles.title}>We also cover these areas</span>
      <div className={styles.grid}>
        {cityData.map((city) => {
          const { name, img, slug, id } = city;
          return (
            <div key={id} className={styles.cityCard}>
              {img && (
                <Link to={`${generatePath(slug)}`}>
                  <Image className={styles.image} image={img} />
                </Link>
              )}
              {name && (
                <Link to={`${generatePath(slug)}`}>
                  <span className={styles.cityName}>{name}</span>
                </Link>
              )}
              <Link className={styles.link} to={`${generatePath(slug)}`}>
                Learn more
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default CityGridByState;
