export const calculatorTypeHelper = (calcType) => {
  if (calcType === 'Car Loan') {
    return 'car';
  }
  if (calcType === 'Caravan Loan') {
    return 'caravan';
  }
  if (calcType === 'Motorbike Loan') {
    return 'motorbike';
  }
  return 'other';
};
