// extracted by mini-css-extract-plugin
export var bomb = "styles-module--bomb--d2c23";
export var content = "styles-module--content--a5854";
export var errorMsg = "styles-module--errorMsg--f9b61";
export var fieldError = "styles-module--fieldError--b4c86";
export var form = "styles-module--form--1456c";
export var formButton = "styles-module--formButton--8c719";
export var fullWidth = "styles-module--fullWidth--e2a28";
export var label = "styles-module--label--d974a";
export var signUpForm = "styles-module--signUpForm--0ff15";
export var splitLeft = "styles-module--splitLeft--e116d";
export var splitRight = "styles-module--splitRight--d62e9";
export var title = "styles-module--title--edb8b";
export var visible = "styles-module--visible--1da9f";