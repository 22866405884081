import React from 'react';
import { Section, Image, TeslaText as Text, TeslaButton as Button } from 'components';

import * as styles from './styles.module.scss';

const StepByStepWithCta = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    background_image: backgroundImage,
    title,
    description,
    cta_text: ctaText,
    cta_link: ctaLink,
    image_alignment: rightAlignedImage,
  } = primary;

  const hasCta = ctaText?.text && ctaLink?.url;
  const alignmentClass = rightAlignedImage ? styles.rightImage : styles.leftImage;

  return (
    <Section sliceName="StepByStepWithCta" noContainer className={`${styles.section} ${alignmentClass}`}>
      <div className={styles.imageContainer}>
        <Image image={backgroundImage} className={styles.image} />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentTop}>
            <Text {...title} />
            <Text {...description} />
          </div>
          <div className={styles.steps}>
            {items.map((item, index) => (
              <>
                <div key={item?.step_title?.text} className={styles.step}>
                  <Text {...item.step_title} />
                  <Text {...item.step_description} />
                </div>
                {items.length - 1 !== index && <div className={styles.divider} />}
              </>
            ))}
          </div>
          {hasCta && (
            <Button to={ctaLink.url} theme="yellow">
              {ctaText.text}
            </Button>
          )}
        </div>
      </div>
    </Section>
  );
};

export default StepByStepWithCta;
