import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import * as styles from './styles.module.scss';
import { ReactComponent as WhiteCross } from '../../../images/white-cross.svg';

const CountrySelectorPopup = ({ location }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [cookies, setCookie] = useCookies(['region']);

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (
      (timezone.includes('Pacific/Auckland') || timezone.includes('Antarctica/McMurdo')) &&
      cookies.region !== 'selected'
    ) {
      setModalVisible(true);
    }
  }, []);

  function toggleModal() {
    const modalVisibility = isModalVisible;
    setModalVisible(!modalVisibility);
    setCookie('region', 'selected');
  }

  return !isModalVisible ? null : (
    <div className={styles.bgOverlay}>
      <div className={styles.popUpContainer}>
        <div className={styles.crossContainer}>
          <WhiteCross className={styles.whiteCross} onClick={() => toggleModal()} />
        </div>
        <p className={styles.text}>We noticed you're currently in New Zealand.</p>
        <p className={styles.text}>Want to visit our New Zealand website instead?</p>
        <a href="https://driva.co.nz">
          <button type="button" className={styles.button}>
            Yes - take me there
          </button>
        </a>
      </div>
    </div>
  );
};

export default CountrySelectorPopup;
