import React from 'react';
import { Section, OnVisible, TeslaText as Text, TeslaButton as Button } from 'components';

import * as styles from './styles.module.scss';

const ComparisonWithCta = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { title, description, cta_text: ctaText, cta_link: ctaLink } = primary;

  const hasCta = ctaText?.text && ctaLink?.url;

  return (
    <Section sliceName="ComparisonWithCta" className={styles.section} containerClassName={styles.contentContainer}>
      <div className={styles.content}>
        <Text {...title} />
        <Text {...description} className={styles.description} />
        {hasCta && (
          <Button className={styles.button} to={ctaLink.url} theme="yellow">
            {ctaText.text}
          </Button>
        )}
      </div>
      <div className={styles.comparisonItems}>
        {items.map((item, index) => (
          <OnVisible key={item?.label?.text} className={styles.comparisonItem} data-row={index + 1} threshold={0.1}>
            <Text {...item?.price} className={styles.price} as="div" />
            <Text {...item?.label} className={styles.label} />
          </OnVisible>
        ))}
      </div>
    </Section>
  );
};

export default ComparisonWithCta;
