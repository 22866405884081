import React from 'react';
import * as styles from './styles.module.scss';

const ChapterTitle = (props) => {
  const { data } = props;
  const { primary } = data;
  const { chapter_title: title } = primary;

  const titleText = title?.text;
  const titleHtml = title?.html;

  const anchorId = titleText ? titleText.replace(/\s+/g, '-').toLowerCase() : '';

  return (
    <div id={anchorId} className={styles.titleContainer}>
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />
    </div>
  );
};

export default ChapterTitle;
