import React from 'react';
import { Section, OnVisible, Image, Link } from 'components';
import { ReactComponent as CGULogo } from '../../images/cgu.svg';
import * as styles from './styles.module.scss';

const IconColumns = (props) => {
  const { data } = props;
  const { items: columns, primary } = data;
  const { content, cta_link: ctaLink, cta_text: ctaText, title, column_style: columnStyle } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaText?.text && ctaLink?.url;
  const colNumber = columns.length > 3 ? styles.fourColumns : '';

  const useColumnBg = columnStyle === 'Background' || columnStyle === 'Powered by CGU';
  const usePoweredByCGU = columnStyle === 'Powered by CGU';

  return (
    <Section
      sliceName="IconColumns"
      containerClassName={styles.container}
      className={`icon-columns ${styles.iconColumns} ${colNumber}`}
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      {usePoweredByCGU && (
        <div className={styles.poweredBy}>
          <strong>Powered by</strong>
          <CGULogo />
        </div>
      )}
      <div className={`${styles.columns} ${useColumnBg ? styles.columnsWithBg : ''}`}>
        {columns.map((column, index) => {
          const { column_content: columnContent, column_title: columnTitle, icon } = column;
          const columnTitleHtml = columnTitle?.html;
          const columnContentHtml = columnContent?.html;
          return (
            <OnVisible
              key={columnTitle.text}
              className={`${styles.column} ${useColumnBg ? styles.columnWithBg : ''}`}
              visibleClassName={styles.visible}
              style={{ transitionDelay: `${index * 0.2}s` }}
            >
              {icon?.url && <Image className={styles.icon} image={icon} />}
              {columnTitleHtml && (
                <div dangerouslySetInnerHTML={{ __html: columnTitleHtml }} className={styles.colTitle} />
              )}
              {columnContentHtml && (
                <div dangerouslySetInnerHTML={{ __html: columnContentHtml }} className={styles.colContent} />
              )}
            </OnVisible>
          );
        })}
      </div>
      {hasCta && (
        <Link className={`button ${styles.ctaButton}`} to={ctaLink?.url}>
          {ctaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default IconColumns;
