// extracted by mini-css-extract-plugin
export var comparisonTable = "styles-module--comparisonTable--5eacc";
export var container = "styles-module--container--64ec8";
export var content = "styles-module--content--269a8";
export var featureTitle = "styles-module--featureTitle--cfbb0";
export var icon = "styles-module--icon--d8969";
export var icons = "styles-module--icons--9046d";
export var imageContainer = "styles-module--imageContainer--fec17";
export var leftImage = "styles-module--leftImage--938bb";
export var others = "styles-module--others--3cb22";
export var rightImage = "styles-module--rightImage--7d068";
export var section = "styles-module--section--56a9a";
export var tableHeader = "styles-module--tableHeader--0e0c4";
export var tableHeaders = "styles-module--tableHeaders--ed2ab";
export var tableRow = "styles-module--tableRow--435f5";
export var textContainer = "styles-module--textContainer--81327";