import { Link as GatsbyLink } from 'gatsby';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import useSessionStorage from '../../../hooks/useSessionStorage';

const Link = (props) => {
  const {
    children,
    activeClassName,
    className,
    to,
    title,
    target = '_blank',
    tabIndex,
    ariaLabel,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
  } = props;

  const sharedProps = {
    className,
    title,
    'aria-label': ariaLabel,
    tabIndex,
    onClick,
    onMouseOver,
    onMouseOut,
  };

  const [queries, setQueries] = useState(null);

  const sessionStorage = useSessionStorage('session');

  useEffect(() => {
    const incomingUtms = sessionStorage.getItem('queriesObj');
    const parsedIncomingUtms = incomingUtms ? JSON.parse(incomingUtms) : null;
    const hasIncomingUtms = parsedIncomingUtms && Object.keys(parsedIncomingUtms).length > 0;
    // Has Incoming UTMS
    if (hasIncomingUtms) {
      const stringifiedIncomingUtms = queryString.stringify(parsedIncomingUtms);
      setQueries(stringifiedIncomingUtms);
    }
  }, [sessionStorage]);

  if (!to) return <span {...sharedProps}>{children}</span>;

  const hasExistingUtms = to.includes('?') && !queries;
  const strippedTo = to.includes('?') && queries ? to.split('?')[0] : to;

  const queriesWithSeparator = queries ? `?${queries}` : '';

  const isExternal = to && to.match(/(http|#|tel:|mailto:)/);
  const containsSiteUrl = process.env.GATSBY_SITE_URL && to && to.indexOf(process.env.GATSBY_SITE_URL) !== -1;

  if (isExternal && !containsSiteUrl) {
    const linkResolver = `${strippedTo}${queriesWithSeparator}`;
    return (
      <a href={linkResolver} target={target} rel="nofollow noopener noreferrer" {...sharedProps}>
        {children}
      </a>
    );
  }

  const withTrailingSlash = `${strippedTo}${queriesWithSeparator}${
    queriesWithSeparator || to.endsWith('/') || hasExistingUtms ? '' : '/'
  }`;

  const linkTo = containsSiteUrl ? withTrailingSlash.replace(process.env.GATSBY_SITE_URL, '') : withTrailingSlash;

  return (
    <GatsbyLink to={linkTo} activeClassName={activeClassName} {...sharedProps}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
