import React from 'react';
import { Section } from 'components';
import CarLoanCalculator from '../../components/Common/Calculator/CarLoanCalculator';
import * as styles from './styles.module.scss';

const CalculatorPage = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, content, ...calculatorProps } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;

  return (
    <Section containerClassName={styles.container} sliceName="Calculator">
      <div className={styles.textContainer}>
        {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      </div>
      <CarLoanCalculator {...calculatorProps} />
    </Section>
  );
};

export default CalculatorPage;
