// extracted by mini-css-extract-plugin
export var colContent = "styles-module--colContent--e1e1b";
export var colTitle = "styles-module--colTitle--58d58";
export var column = "styles-module--column--b4dc1";
export var columnWithBg = "styles-module--columnWithBg--196c4";
export var columns = "styles-module--columns--7889c";
export var columnsWithBg = "styles-module--columnsWithBg--0f911";
export var content = "styles-module--content--21252";
export var ctaButton = "styles-module--ctaButton--24b7d";
export var fourColumns = "styles-module--fourColumns--b4a08";
export var icon = "styles-module--icon--e703d";
export var iconColumns = "styles-module--iconColumns--b3766";
export var poweredBy = "styles-module--poweredBy--45bfb";
export var title = "styles-module--title--fc852";
export var visible = "styles-module--visible--31801";