// extracted by mini-css-extract-plugin
export var colContent = "styles-module--colContent--17a4c";
export var colTitle = "styles-module--colTitle--0352b";
export var column = "styles-module--column--aa1c6";
export var content = "styles-module--content--3b18b";
export var desktopCta = "styles-module--desktopCta--3dc22";
export var icon = "styles-module--icon--067ed";
export var iconContainer = "styles-module--iconContainer--9f878";
export var poweredBy = "styles-module--poweredBy--6ae46";
export var poweredByContainer = "styles-module--poweredByContainer--294c7";
export var rowItem = "styles-module--rowItem--b9d3f";
export var rowItemBody = "styles-module--rowItemBody--9aa3a";
export var rowItemProduct = "styles-module--rowItemProduct--53855";
export var rowItemTop = "styles-module--rowItemTop--dc5c0";
export var rows = "styles-module--rows--0c586";
export var stackedComparison = "styles-module--stackedComparison--61399";
export var tabletCta = "styles-module--tabletCta--b0746";
export var title = "styles-module--title--08a1f";
export var visible = "styles-module--visible--a152d";