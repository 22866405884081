// extracted by mini-css-extract-plugin
export var button = "styles-module--button--63d3b";
export var container = "styles-module--container--2f313";
export var content = "styles-module--content--7cdd7";
export var feature = "styles-module--feature--084c3";
export var featureVisible = "styles-module--featureVisible--a0e38";
export var features = "styles-module--features--b245d";
export var icon = "styles-module--icon--fb8e5";
export var image = "styles-module--image--57be9";
export var imageLeft = "styles-module--imageLeft--9f6ff";
export var itemContent = "styles-module--itemContent--9bf1c";
export var itemTitle = "styles-module--itemTitle--7515a";
export var mintBackground = "styles-module--mintBackground--41c1c";
export var noPillShape = "styles-module--noPillShape--e0a7b";
export var purpleDots = "styles-module--purpleDots--d6652";
export var textRight = "styles-module--textRight--810dc";
export var title = "styles-module--title--c08e4";
export var twoColsFeatureList = "styles-module--twoColsFeatureList--6d9b3";
export var yellowDots = "styles-module--yellowDots--31876";