// extracted by mini-css-extract-plugin
export var ThreeColsReviews = "styles-module--ThreeColsReviews--7cd32";
export var card = "styles-module--card--f230a";
export var cardContent = "styles-module--cardContent--5d3de";
export var cardsContainer = "styles-module--cardsContainer--bc3f7";
export var chevron = "styles-module--chevron--9b019";
export var chevronButtonDown = "styles-module--chevronButtonDown--347cc";
export var chevronButtonUp = "styles-module--chevronButtonUp--190a0";
export var contentWrapper = "styles-module--contentWrapper--ffa38";
export var cta = "styles-module--cta--fa0a4";
export var logo = "styles-module--logo--97678";
export var readReview = "styles-module--readReview--aa18c";
export var scrollRef = "styles-module--scrollRef--cc9b2";
export var showAllRow = "styles-module--showAllRow--1d487";
export var title = "styles-module--title--63ccd";
export var viewAll = "styles-module--viewAll--385e0";
export var viewAllCTA = "styles-module--viewAllCTA--4eefa";