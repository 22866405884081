/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Skeleton, Link, RateItem } from 'components';
import * as styles from './styles.module.scss';

import { ReactComponent as Arrow } from '../../../images/calculator-arrow.svg';
import { ReactComponent as MobileArrow } from '../../../images/calculator-arrow-mobile.svg';

const Results = (props) => {
  const { repaymentFrequency, returnData, animate, isBadResponse, decimalHelper, ctaText, ctaLink } = props;

  return (
    <>
      <div>
        {/* Weekly repaymennt */}
        <div className={styles.finance}>
          <p className={styles.tagline}>Repayments</p>
          {returnData && !returnData?.error && (
            <>
              <h3 className={animate ? styles.animateMainText : styles.mainText} id="repayment">
                {returnData[repaymentFrequency?.toLowerCase()]?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
              </h3>
              <p className={styles.tagline}>
                {repaymentFrequency === 'Weekly'
                  ? '/ week'
                  : repaymentFrequency === 'Fortnightly'
                    ? '/ fortnight'
                    : '/ month'}
              </p>
            </>
          )}
          {!returnData && !isBadResponse && <h3 className={styles.mainText}>77</h3>}
          {returnData?.error && (
            <h3 className={styles.mainTextBadResponse} style={{ opacity: !isBadResponse && 0 }}>
              ...
            </h3>
          )}
          {animate && !isBadResponse && <Skeleton />}
        </div>

        <div className={styles.rateRow}>
          <RateItem
            type="interest"
            returnData={returnData}
            decimalHelper={decimalHelper}
            isBadResponse={isBadResponse}
            animate={animate}
          />
          <RateItem
            type="comparison"
            returnData={returnData}
            decimalHelper={decimalHelper}
            isBadResponse={isBadResponse}
            animate={animate}
          />
        </div>
      </div>
      <div className={styles.ctaContainer}>
        <Arrow className={styles.calculatorArrow} />
        <MobileArrow className={styles.calculatorArrowMobile} />
        {!isBadResponse && (
          <Link to={ctaLink || 'https://dash.driva.com.au'}>
            <button type="submit" className={`button ${styles.formButton}`}>
              {ctaText || 'Get pre-approved'}
            </button>
          </Link>
        )}
        {isBadResponse && (
          <button
            type="submit"
            className={`button ${styles.formButton}`}
            style={{
              color: isBadResponse && '#ffffff',
              backgroundColor: isBadResponse && '#3E3F42',
              border: isBadResponse && 0,
            }}
          >
            Financing not available :(
          </button>
        )}
        <p className={styles.infoText}>We match your unique profile to the best rates from across 30+ lenders.</p>
      </div>
    </>
  );
};

export default Results;
