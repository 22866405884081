import React from 'react';
import { Section, Image, OnVisible, Link } from 'components';
import { ReactComponent as PurpleDots } from '../../images/purple-dots.svg';
import { ReactComponent as YellowDots } from '../../images/yellow-dots.svg';
import * as styles from './styles.module.scss';

const TwoColsFeatureList = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    content,
    image,
    background,
    graphics,
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    no_pill_shape: noPill,
  } = primary;

  // content checks
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;
  const noPillShape = noPill ? styles.noPillShape : '';
  const backgroundColor = background === 'Mint' ? styles.mintBackground : '';
  const classNames = `${styles.twoColsFeatureList} ${noPillShape} ${backgroundColor}`;

  return (
    <Section sliceName="TwoColsFeatureList" className={`${classNames}`} containerClassName={styles.container}>
      {/* IMAGE LEFT */}
      {image?.url && (
        <div className={styles.imageLeft}>
          {graphics === 'Purple dots' && <PurpleDots className={styles.purpleDots} />}
          {graphics === 'Yellow dots' && <YellowDots className={styles.yellowDots} />}
          <Image image={image} className={styles.image} objectFit="contain" />
        </div>
      )}
      {/* TEXT RIGHT */}
      <div className={styles.textRight}>
        {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {content?.text && <div className={styles.content} dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        {/* FEATURES */}
        <div className={styles.features}>
          {items.map((item) => {
            const { content: itemContent, icon, title: itemTitle } = item;
            return (
              <OnVisible className={styles.feature} key={itemTitle.text} visibleClassName={styles.featureVisible}>
                {icon?.url && <Image className={styles.icon} image={icon} objectFit="contain" />}
                {itemTitle?.text && (
                  <div className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: itemTitle.html }} />
                )}
                {itemContent?.text && (
                  <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: itemContent.html }} />
                )}
              </OnVisible>
            );
          })}
        </div>
        {hasCta && (
          <Link to={ctaLink.url} className={`button ${styles.button}`}>
            {ctaText?.text}
          </Link>
        )}
      </div>
    </Section>
  );
};

export default TwoColsFeatureList;
