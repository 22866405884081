import React from 'react';
import { Section } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './styles.module.scss';
import CategoryThreeCardCol from '../../components/Blog/CategoryThreeCardCol';

const ThreeColumnCards = ({ data }) => {
  const cardData = useStaticQuery(Queries);

  const {
    subcategoryToyota: { nodes: toyotanode },
    subcategoryKia: { nodes: kianode },
    subcategoryHyundai: { nodes: hyundainode },
    subcategoryTesla: { nodes: teslanode },
    subcategoryPolestar: { nodes: polestarnode },
    subcategoryMazda: { nodes: mazdanode },
    subcategoryHonda: { nodes: hondanode },
    subcategoryVolkswagen: { nodes: volkswagennode },
    subcategoryBMW: { nodes: bmwnode },
    subcategoryHolden: { nodes: holdennode },
    subcategoryNissan: { nodes: nissannode },
    subcategoryFord: { nodes: fordnode },
    subcategoryMercedes: { nodes: mercedesnode },
    subcategoryMitsubishi: { nodes: mitsubishinode },
    subcategoryLexus: { nodes: lexusnode },
    subcategoryHaval: { nodes: havalnode },
    subcategorySubaru: { nodes: subarunode },
    subcategorySuzuki: { nodes: suzukinode },
    subcategoryRenault: { nodes: renaultnode },
  } = cardData;

  const allCategories = [
    { title: 'Toyota Car Buying Guides', nodes: toyotanode },
    { title: 'Kia Car Buying Guides', nodes: kianode },
    { title: 'Hyundai Car Buying Guides', nodes: hyundainode },
    { title: 'Tesla Car Buying Guides', nodes: teslanode },
    { title: 'Polestar Car Buying Guides', nodes: polestarnode },
    { title: 'Mazda Car Buying Guides', nodes: mazdanode },
    { title: 'Honda Car Buying Guides', nodes: hondanode },
    { title: 'Volkswagen Car Buying Guides', nodes: volkswagennode },
    { title: 'BMW Car Buying Guides', nodes: bmwnode },
    { title: 'Holden Car Buying Guides', nodes: holdennode },
    { title: 'Nissan Car Buying Guides', nodes: nissannode },
    { title: 'Ford Car Buying Guides', nodes: fordnode },
    { title: 'Mercedes Car Buying Guides', nodes: mercedesnode },
    { title: 'Mitsubishi Car Buying Guides', nodes: mitsubishinode },
    { title: 'Lexus Car Buying Guides', nodes: lexusnode },
    { title: 'Haval Car Buying Guides', nodes: havalnode },
    { title: 'Subaru Car Buying Guides' , nodes: subarunode },
    { title: 'Suzuki Car Buying Guides', nodes: suzukinode },
    { title: 'Renault Car Buying Guides', nodes: renaultnode },
  ];

  return (
    <Section className={styles.threeCardContainer} sliceName="ThreeColumnTable" noContainer>
      <div className={styles.threeCardDiv}>
        {allCategories.map((category) => {
          if (category.title.toLowerCase().split(' ')[0] === data.primary.subcategory.uid) {
            return (
              <CategoryThreeCardCol
                className={styles.threeCardSection}
                categoryPosts={category?.nodes}
                title={category?.title}
                displayViewAllVal='none'
              />
            );
          }
        })}
      </div>
    </Section>
  );
};

export default ThreeColumnCards;

export const Queries = graphql`
  query Queries {
    subcategoryToyota: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "toyota" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryKia: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "kia" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryHyundai: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "hyundai" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryTesla: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "tesla" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryPolestar: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "polestar" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryMazda: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "mazda" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryHonda: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "honda" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryVolkswagen: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "volkswagen" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryBMW: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "bmw" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryHolden: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "holden" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryNissan: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "nissan" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryFord: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "ford" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryMercedes: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "mercedes" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryMitsubishi: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "mitsubishi" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryLexus: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "lexus" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryHaval: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "haval" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategorySubaru: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "subaru" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategorySuzuki: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "suzuki" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    subcategoryRenault: allPrismicBlogPost(
      filter: { data: { sub_category: { uid: { eq: "renault" } } } }
      limit: 3
      sort: { fields: data___timestamp, order: DESC }
    ) {
      nodes {
        uid
        data {
          title {
            html
            text
          }
          hero_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, aspectRatio: 2.333333333333)
              }
            }
          }
          sub_category {
            uid
            document {
              ... on PrismicCarBuyingGuideSubcategories {
                uid
                data {
                  subcategory_name {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
